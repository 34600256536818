import { BackButton, Button, ChipStatus, Content, ContentHeader, Dialog, DialogAction, DialogContent, DialogRef, DialogTitle, LabelItem, QueryState, Stack, Thumbnail } from 'src/app/components';
import { PageTitle } from 'src/app/components/Layout/header/HeaderTitle';
import { useTabDetailParticipantDetail } from './TabParticipantDetail.hook';
import CouponList from './CouponList';
import { customLDDetailParticipantStatus } from '../LuckyDrawDetailTabParticipant';
import { useEffect, useRef } from 'react';

export default function TabParticipantDetail() {
  const state = useTabDetailParticipantDetail();
  const alertUnverifyUser = useRef<DialogRef>(null);
  const data = state.participantDetail.data;

  useEffect(() => {
    if (data?.identity_status === "UNVERIFIED") {
      alertUnverifyUser.current?.show()
    }
  }, [data])

  return (
    <>
      <BackButton />
      <Content>
        <ContentHeader title={'Detail Customer'} />
        <PageTitle description="Customer" breadcrumbs={state.breadcrumbs}>
          Campaign
        </PageTitle>
        <QueryState query={state.participantDetail}>
          <div className='px-8 py-6'>
            <LabelItem className='gap-1' label='Customer ID' value={data?.member_code} />
            <LabelItem className='gap-1' label='Customer Name' value={data?.fullname} />
            <LabelItem className='gap-1' label='Gender' value={data?.gender} />
            <LabelItem className='gap-1' label='Phone Number' value={data?.phone_number} />
            <LabelItem className='gap-1' label='Email' value={data?.email} />
            <LabelItem className='gap-1' label='ID Type' value={data?.identity_type} />
            <LabelItem className='gap-1' label='ID Number' value={data?.identity_number} />
            <LabelItem className='gap-1' label='ID Photo'>
              <Thumbnail src={data?.identity_image} />
            </LabelItem>
            <LabelItem className='gap-1' label='Customer Status'>
              <div>
                <ChipStatus state="custom" customOpt={customLDDetailParticipantStatus(data?.status)} />
              </div>
            </LabelItem>
            <LabelItem className='gap-1' label='Total Coupon' value={data?.total_coupon} />
            <LabelItem className='gap-1' label='Total Coupon Printed' value={data?.total_coupon_printed} />
            <LabelItem className='gap-1' label='Total Valid Coupon' value={data?.total_valid_coupon} />
            <div className='pt-6'>
              <CouponList {...data}></CouponList>
            </div>
          </div>
        </QueryState>
      </Content>
      <Dialog
        size="xs"
        ref={alertUnverifyUser}
      >
        <DialogTitle title={"The customer has not completed ID verification."} />
        <DialogContent>
          <p className='text-base'>
            Customer {data?.fullname} has not completed ID verification. Please verify the ID first before printing the Lucky Draw Coupon.
          </p>
          <Stack
            direction="row"
            justify="end"
          >
            <Button className='min-w-[128px]' onClick={() => alertUnverifyUser.current?.hide()}>OK</Button>
          </Stack>
        </DialogContent>
      </Dialog>
    </>
  )
}