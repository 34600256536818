import { useGetLuckyDrawDetailGeneralInfo, useSubmitSummaryLuckyDraw } from "../../../providers/LuckyDraw.provider";

export function useLuckyDrawForm4(id?: string) {
    const submit = useSubmitSummaryLuckyDraw()
    const luckyDrawDetail = useGetLuckyDrawDetailGeneralInfo(id, true)

    return ({
      submit,
      luckyDrawDetail,
    })
}