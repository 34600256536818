import { useDeletePrize, useGetPrizeList, useSaveLuckyDrawGeneral } from '../../../providers/LuckyDraw.provider';

export function useLuckyDrawForm2Hook(campaign_id: string) {

  const prizeListList = useGetPrizeList(campaign_id);
  const deletePrize = useDeletePrize(campaign_id);
  const saveLuckyDrawGeneral = useSaveLuckyDrawGeneral();

  return {
    prizeListList,
    deletePrize,
    saveLuckyDrawGeneral,
  };
}
