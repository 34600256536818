import dayjs from 'dayjs';
import { TableRow, TableCell, LabelItem, ChipStatus, Thumbnail, RenderHTML, Content, Table, TableHead, TableBody } from 'src/app/components';
import { MallModel, StoreCategoryModel, StoreModel } from 'src/app/modules/directory/models';
import { LuckyDrawDetailModel, PrizeListModel, PrizeRuleDetail, TransactionRule, LuckyDrawRuleModel } from '../../../models/LuckyDraw.model';
import { translateCampaignType } from '../LuckyDraw.page';
import { translatePrizeType } from '../LuckyDrawForm/LuckyDrawForm2.page';
import { defaultLuckyDrawRuleValues } from '../LuckyDrawForm/LuckyDrawForm3.hook';
import { formatRupiahSpaced } from 'src/app/utils/input-utils';

interface LuckyDrawDetailTabGeneralInfoInterface {
  data?: LuckyDrawDetailModel
  useStatus?: boolean
}

function LuckyDrawDetailTabGeneralInfo({ data, useStatus = true }: Readonly<LuckyDrawDetailTabGeneralInfoInterface>) {

  const renderTablePrize = (item: PrizeListModel, index: number) => {
    return (
      <TableRow key={`TablePrize-${index}`}>
        <TableCell {...{ index }} />
        <TableCell value={item?.name} />
        <TableCell value={translatePrizeType(item?.type)} />
        <TableCell value={item?.quantity} />
        <TableCell value={item?.draw_directory} renderValue={(directory) => {
          return (directory.directory_name)
        }} />
        <TableCell
          renderValue={(draw_date: string) => {
            return (dayjs(draw_date).format('DD/MM/YYYY'))
          }}
          className="w-[145px]"
          value={item?.draw_date}
        />
      </TableRow>
    )
  }

  const getPrizeName = (prize: PrizeRuleDetail) => {
    if (prize.type === 'GRAND_PRIZE') {
      const prizes = Array.isArray(prize.prizes) ? prize.prizes : [prize.prizes]; // Ensure it's an array
      return prizes.length > 0 ? prizes[0]?.name ?? '-' : '-';
    }
    return 'CONSOLATION';
  };

  const renderPrize = (prizes: PrizeRuleDetail[] = [{ id: '-', prize_ids: ['-'], coupon_quantity: 0, max_coupon_per_transaction: 0 }]) => {
    return prizes.map((prule: PrizeRuleDetail, index: number) => {
      return (
        <li key={`prule-${index}`} className='text-[0.75rem] list-disc ml-4'>
          {
            `${getPrizeName(prule)} ${prule.coupon_quantity ? ' - ' + prule.coupon_quantity + ' Coupon' : ''} ${prule.max_coupon_per_transaction ? ' - ' + prule.max_coupon_per_transaction + 'Max Coupon' : ''} `
          }
        </li>)
    })
  }

  const renderPaymentMethod = (payment_categories: any[]) => {
    const methodNames = payment_categories.map((item: any) => ' ' + item.payment_category_name)
    return <>{methodNames?.toString()}</>
  }

  const renderPaymentBy = (payment_methods: any[]) => {
    const byNames = payment_methods.map((item: any) => ' ' + item.method_name)
    return <>{byNames?.toString()}</>
  }

  const renderTransactionList = (trule: TransactionRule, index: number) => {
    return (
      <div key={`Transaction-${index + 1}`}>
        <p className='text-[0.75rem]'>
          {`Transaction ${index + 1}`}
        </p>
        <LabelItem className='gap-1' label='Minimum Transaction' value={trule?.minimum_transaction ? formatRupiahSpaced(trule?.minimum_transaction) : '-'} />
        <LabelItem className='gap-1' label='Payment Method' value={trule?.payment_categories} renderValue={renderPaymentMethod} />
        <LabelItem className='gap-1' label='Payment By' value={trule?.payment_methods} renderValue={renderPaymentBy} />
        <LabelItem className='gap-1' label='Mixable Coupon' value={trule?.is_mixable === true ? 'Yes' : trule?.is_mixable === false ? 'No' : '-'} />
        <LabelItem className='gap-1' label='Valid for Multiple Entries' value={trule?.is_multiple_entry === true ? 'Yes' : trule?.is_multiple_entry === false ? 'No' : '-'} />
        <LabelItem className='gap-1' label='Grand Prizes'>
          {
            <ul>
              {renderPrize(trule?.prizes)}
            </ul>
          }
        </LabelItem>
      </div>
    )
  }

  const renderCategories = (categories: StoreCategoryModel[] = [{ id: '-', category_name: '-' }]) => {
    const categoryNames = categories.map((item: StoreCategoryModel) => ' ' + item.category_name)
    return <>{categoryNames?.toString()}</>
  }

  const renderStore = (merchants: StoreModel[] = [{ id: '-', merchant_name: '-' }]) => {
    const storeNames = merchants.map((item: StoreModel) => ' ' + item.merchant_name)
    return <>{storeNames?.toString()}</>
  }

  const renderStoreList = (item: LuckyDrawRuleModel, index: number) => {
    return (
      <div key={`Store-${index + 1}`}>
        <p className='text-[0.75rem]'>
          {`Store ${index + 1}`}
        </p>
        <LabelItem className='gap-1' label='Category' value={item?.merchant_categories} renderValue={renderCategories} />
        <LabelItem className='gap-1' label='Store' value={item?.merchants} renderValue={renderStore} />
        <div className='p-4'>
          {(item?.prize_transaction_rules ?? [{ id: '-' }]).map(renderTransactionList)}
        </div>
      </div>
    )
  }

  function getDirectoryName(directories: LuckyDrawDetailModel['directories']) {
    const mallNames = directories?.map((item: MallModel) => ' ' + item.directory_name)
    return mallNames ? <>{mallNames?.toString()}</> : '-'
  }

  function getPeriod(period: { start_date?: string, end_date?: string }) {
    return (
      (period.start_date && period.end_date) ? (
        <div className='text-[0.75rem]'>
          {dayjs(period.start_date).format('DD/MM/YYYY')} - {dayjs(period.end_date).format('DD/MM/YYYY')}
        </div>
      ) : ('-')
    )
  }

  return (
    <div>
      <p className='pb-6 font-bold text-[0.75rem]'>
        General Info
      </p>
      {
        useStatus ? (<LabelItem className='gap-1' label='Status'>
          <div>
            <ChipStatus state={data?.status} />
          </div>
        </LabelItem>) : (<></>)
      }
      <LabelItem className='gap-1' label='Campaign Type' value={translateCampaignType(data?.category)} />
      <LabelItem className='gap-1' label='Campaign Name' value={data?.name} />
      <LabelItem className='gap-1' label='Mall' value={getDirectoryName(data?.directories)} />
      <LabelItem className='gap-1' label='Period'>
        {getPeriod({ start_date: data?.start_date, end_date: data?.end_date })}
      </LabelItem>
      <LabelItem className='gap-1' label='Max Days to Print After Receipt Approved' value={data?.max_day_to_print ? (data?.max_day_to_print + ' Days') : '-'} />
      <LabelItem className='gap-1' label='Max Days to select a coupon after Snap Receipt' value={data?.max_day_to_choose_prize ? (data?.max_day_to_choose_prize + ' Days') : '-'} />
      <LabelItem className='gap-1' label='Consolation Name in PG Card Apps' value={data?.consolation_alias} />
      <LabelItem className='gap-1' label='Campaign Image'>
        <Thumbnail src={data?.image} />
      </LabelItem>
      <LabelItem className='gap-1' label='Terms & Condition (EN)'>
        <RenderHTML content={data?.tnc_eng === '' ? '-' : data?.tnc_eng} />
      </LabelItem>
      <LabelItem className='gap-1' label='Terms & Condition (ID)'>
        <RenderHTML content={data?.tnc_idn === '' ? '-' : data?.tnc_idn} />
      </LabelItem>
      <p className='py-6 font-bold text-[0.75rem]'>
        Prize
      </p>
      <Content className='px-6'>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell index />
              <TableCell>Prize Name</TableCell>
              <TableCell>Type</TableCell>
              <TableCell>Quantity</TableCell>
              <TableCell>Draw Location</TableCell>
              <TableCell>Draw Date</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {data?.prizes?.map(renderTablePrize)}
          </TableBody>
        </Table>
      </Content>
      <p className='py-6 font-bold text-[0.75rem]'>
        Store
      </p>
      {(data?.prize_rules?.length ? data.prize_rules : defaultLuckyDrawRuleValues.prize_rules).map(renderStoreList)}
    </div>
  )
}

export default LuckyDrawDetailTabGeneralInfo
