import axios, { AxiosResponse } from "axios";
import { BaseResponse, Pagination, Response } from "src/app/models/api.types";
import { CampaignModel } from "../models/Campaign.model";
import { LuckyDrawDetailModel, LuckyDrawModel, LuckyDrawParticipantModel, LuckyDrawRuleModel, ParticipantCouponModel, ParticipantDetailModel, ParticipantSummaryModel, PrizeListModel } from '../models/LuckyDraw.model';

export const URL_CAMPAIGN = '/v1/cms/campaigns';
export const URL_CAMPAIGN_LUCKY_DRAW = `${URL_CAMPAIGN}/lucky-draw`;
export const URL_CAMPAIGN_LUCKY_DRAW_PRIZES = (campaign_id: string) => {
  return `${URL_CAMPAIGN_LUCKY_DRAW}/${campaign_id}/prizes`;
}
export const URL_CAMPAIGN_LUCKY_DRAW_SUMMARY = (campaign_id: string) => {
  return `${URL_CAMPAIGN_LUCKY_DRAW}/${campaign_id}/summary`;
}
export const URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT = (campaign_id: string) => {
  return `${URL_CAMPAIGN_LUCKY_DRAW}/${campaign_id}/participants`;
}
export const URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_SUMMARY = (campaign_id: string) => {
  return `${URL_CAMPAIGN_LUCKY_DRAW}/${campaign_id}/participant-summary`;
}
export const URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_DETAIL = (campaign_id: string, member_id: string) => {
  return `${URL_CAMPAIGN_LUCKY_DRAW}/${campaign_id}/participants/${member_id}`;
}
export const URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_COUPONS = (campaign_id: string, member_id: string) => {
  return `${URL_CAMPAIGN_LUCKY_DRAW}/${campaign_id}/participants/${member_id}/coupons`;
}


// get campaign
export async function getCampaign(params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Pagination<CampaignModel[]>>>(`${URL_CAMPAIGN}`, { params, signal });
}

// get campaign detail
export async function getCampaignDetail(id: string, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<CampaignModel>>>(`${URL_CAMPAIGN}/${id}`, { signal });
}

// save campaign
export function saveCampaign(params: CampaignModel) {
  if (!params.id) {
    return axios.post<BaseResponse<Response<CampaignModel>>>(URL_CAMPAIGN, params);
  }
  return axios.put<BaseResponse<Response<CampaignModel>>>(`${URL_CAMPAIGN}/${params.id}`, params);
}

// delete campaign
export async function deleteCampaign(id: string) {
  return axios.delete<BaseResponse<Response<{}>>>(`${URL_CAMPAIGN}/${id}`);
}

// get lucky draw
export async function getLuckyDrawList(params: any, signal?: AbortSignal): Promise<AxiosResponse<BaseResponse<Pagination<LuckyDrawModel[]>>>> {
  return axios.get<BaseResponse<Pagination<LuckyDrawModel[]>>>(`${URL_CAMPAIGN_LUCKY_DRAW}`, { params, signal });
}

// get lucky draw prize list
export async function getLuckyDrawPrizeList(params: any, campaign_id: string, signal?: AbortSignal): Promise<AxiosResponse<BaseResponse<Pagination<PrizeListModel[]>>>> {
  // return axios.get<BaseResponse<Pagination<PrizeListModel[]>>>(`${URL_CAMPAIGN_LUCKY_DRAW}`, { params, signal });
  return axios.get<BaseResponse<Pagination<PrizeListModel[]>>>(`${URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id)}`, { params, signal });
}

export function submitPrize(campaign_id: string, params: any) {
  if (params.id) {
    return axios.put<BaseResponse<Response<PrizeListModel>>>(`${URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id)}/${params.id}`, params);
  }
  return axios.post<BaseResponse<Response<any>>>(URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id), params);
}

export function deleteLuckyDrawPrize(campaign_id: string, prize_id: string) {
  return axios.delete<BaseResponse<Response<PrizeListModel>>>(`${URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id)}/${prize_id}`);
}

// get Lucky Draw Rule
export async function getLuckyDrawRule(id: string, signal?: AbortSignal): Promise<AxiosResponse<BaseResponse<Pagination<LuckyDrawRuleModel[]>>>> {
  return axios.get<BaseResponse<Pagination<LuckyDrawRuleModel[]>>>(`${URL_CAMPAIGN_LUCKY_DRAW}/${id}/rules`, { signal });
}

// save Lucky Draw General Data
export function saveLuckyDrawGeneral(params: LuckyDrawModel) {
  if (!params.id) {
    return axios.post<BaseResponse<Response<LuckyDrawModel>>>(URL_CAMPAIGN_LUCKY_DRAW, params);
  }
  return axios.put<BaseResponse<Response<LuckyDrawModel>>>(`${URL_CAMPAIGN_LUCKY_DRAW}/${params.id}`, params);
}

// save Lucky Draw Rule Data
export function saveLuckyDrawRule(id: string, params: LuckyDrawModel[]) {
  return axios.post<BaseResponse<Pagination<LuckyDrawModel[]>>>(`${URL_CAMPAIGN_LUCKY_DRAW}/${id}/rules`, params);
}

// save Submit Summary Lucky Draw
export function submitSummaryLuckyDraw(campaign_id: string) {
  return axios.patch<BaseResponse<Response<LuckyDrawModel>>>(`${URL_CAMPAIGN_LUCKY_DRAW_SUMMARY(campaign_id)}`);
}

// delete lucky draw
export function deleteLuckyDraw(id: string) {
  return axios.delete<BaseResponse<Response<LuckyDrawDetailModel>>>(`${URL_CAMPAIGN_LUCKY_DRAW}/${id}`);
}

// get getLuckyDrawDetail
export async function getLuckyDrawDetail(id: string, params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<LuckyDrawDetailModel>>>(`${URL_CAMPAIGN_LUCKY_DRAW}/${id}`, { params, signal });
}

// get getLuckyDrawParticipantList
export async function getLuckyDrawParticipantList(campaign_id: string, params: any, signal?: AbortSignal): Promise<AxiosResponse<BaseResponse<Pagination<LuckyDrawParticipantModel[]>>>> {
  return axios.get<BaseResponse<Pagination<LuckyDrawParticipantModel[]>>>(URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT(campaign_id), { params, signal });
}

// get getLuckyDrawParticipantSummary
export async function getLuckyDrawParticipantSummary(campaign_id: string, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<ParticipantSummaryModel>>>(URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_SUMMARY(campaign_id), { signal });
}

// get getParticipantDetail
export async function getParticipantDetail(campaign_id: string, member_id: string, params: any, signal?: AbortSignal) {
  return axios.get<BaseResponse<Response<ParticipantDetailModel>>>(URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_DETAIL(campaign_id, member_id), { params, signal });
}

// get getLuckyDrawParticipantCouponList
export async function getLuckyDrawParticipantCouponList(campaign_id: string, member_id: string, params: any, signal?: AbortSignal): Promise<AxiosResponse<BaseResponse<Pagination<ParticipantCouponModel[]>>>> {
  return axios.get<BaseResponse<Pagination<ParticipantCouponModel[]>>>(URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_COUPONS(campaign_id, member_id), { params, signal });
}

// download export list participant
export async function getExportListParticipant(campaign_id: string, params?: any) {
  return axios.get<Blob>(URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT(campaign_id), { params, responseType: 'blob' });
}

export function submitPrintCoupon(campaign_id: string, member_id: string, action_type: 'print' | 'reprint', params: any) {
  return axios.post<BaseResponse<Pagination<any>>>(`${URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_DETAIL(campaign_id, member_id)}/${action_type}`, params);
}

export function submitPostPrintCoupon(campaign_id: string, member_id: string, params: any) {
  return axios.post<BaseResponse<Pagination<any>>>(`${URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_DETAIL(campaign_id, member_id)}/post-print`, params);
}

export async function executePrintCoupon(params: any, key: string) {
  const REACT_APP_API_PRINT = process.env.REACT_APP_API_PRINT
  // const REACT_APP_API_PRINT = 'http://192.168.80.228:9101'
  console.log(REACT_APP_API_PRINT)

  let controller: AbortController | null = null;
  let timeoutId: NodeJS.Timeout | null = null;

  if (key === 'check') {
    controller = new AbortController();
    timeoutId = setTimeout(() => controller?.abort(), 1000); // Set timeout for 5 seconds
  }

  try {
    const response = await fetch(`${REACT_APP_API_PRINT}/${key}`, {
      method: "POST",
      headers: { "Content-Type": "application/json" },
      body: JSON.stringify(params),
      signal: controller?.signal,
    });

    if (timeoutId) clearTimeout(timeoutId);

    if (!response.ok) {
      throw new Error(`HTTP error! Status: ${response.status}`);
    }
    return await response.json();
  } catch (error) {
    console.error("Error saving lucky draw:", error);
    throw error;
  }
}