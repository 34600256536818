import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import CampaignScreens from 'src/app/modules/campaign/CampaignScreens'
import { ParticipantCouponModel } from 'src/app/modules/campaign/models/LuckyDraw.model'
import { useGetLuckyDrawDetailParticipantDetail, useLuckyDrawParticipantCouponList, useLuckyDrawParticipantReprintCouponList, useSubmitPostPrintCoupon, useSubmitPrintCoupon } from 'src/app/modules/campaign/providers/LuckyDraw.provider'
import { MallModel } from 'src/app/modules/directory/models'
import { useGetProfile } from 'src/app/modules/user_and_permissions/providers/User.provider'

export interface PrintCouponFormModel {
  print_location?: MallModel,
  draw_location?: MallModel,
  member_coupon_ids?: string[],
  print_directory_id?: string
  draw_directory_id?: string
  reprint_description?: string,
  reason?: string
}

export function useTabDetailParticipantDetail() {
  const { id, participantId } = useParams<{ id: string, participantId: string }>()
  const participantDetail = useGetLuckyDrawDetailParticipantDetail(id, participantId)
  const participantCouponList = useLuckyDrawParticipantCouponList(id, participantId)
  const participantReprintCouponList = useLuckyDrawParticipantReprintCouponList(id, participantId, { is_printed: true, limit: 1000, page: 1 })
  const [listCouponOnPrint, setListCouponOnPrint] = useState<ParticipantCouponModel[] | undefined>([])
  const readyPrint = useSubmitPrintCoupon(id, participantId)
  const postPrint = useSubmitPostPrintCoupon(id, participantId)
  const profile = useGetProfile();

  const breadcrumbs = [
    {
      isActive: false,
      path: [CampaignScreens.LIST_LUCKY_DRAW.PATH].join(),
      title: 'Lucky Draw',
    },
    {
      isActive: false,
      path: CampaignScreens.DETAIL_LUCKY_DRAW.PATH.replace(':id', id).replace(':path', 'participant'),
      title: 'Detail',
    },
    { isActive: false, path: '', title: '', isSeparator: true },
  ]

  const printDefaultForm: PrintCouponFormModel = {
    print_location: undefined,
    draw_location: undefined,
    member_coupon_ids: [],
    print_directory_id: '',
    reprint_description: ''
  }

  const confirmPrintDefaultForm: PrintCouponFormModel = {
    member_coupon_ids: [],
    reason: ''
  }

  const formPrint = useForm({ defaultValues: printDefaultForm, reValidateMode: 'onChange' })
  const formConfirmPrint = useForm({ defaultValues: confirmPrintDefaultForm, reValidateMode: 'onChange' })
  const couponses = formPrint.watch('member_coupon_ids')
  const checkedPrinted = formConfirmPrint.watch('member_coupon_ids')
  return {
    breadcrumbs,
    participantDetail,
    formPrint,
    formConfirmPrint,
    participantCouponList,
    participantReprintCouponList,
    listCouponOnPrint, setListCouponOnPrint,
    couponses,
    checkedPrinted,
    readyPrint,
    postPrint,
    profile,
  }
}
