import { useHistory } from "react-router-dom";
import { Button } from "../../Inputs";
import { ButtonProps } from "../../Inputs/Button/Button.types";
import { BakcIcon } from "../../Icons";
import { twMerge } from "tailwind-merge";
import { Alert, DialogRef } from "../../Feedback";
import { Typography } from "../../Display";
import { useEffect, useRef } from "react";

interface BackButtonProps extends ButtonProps {
  useAlert?: boolean
}
function BackButton({
  className,
  variant,
  useAlert,
  ...props
}: Readonly<BackButtonProps>) {
  const history = useHistory();
  const alertLeavingRef = useRef<DialogRef>(null);
  const backButtonClicked = (key: boolean) => {
    alertLeavingRef.current?.hide()
    if (key) {
      history.block(() => {});
      history.goBack()
    }
  }

  useEffect(() => {
    const unblock = history.block((_, action) => {
      if (action === "POP" && useAlert) {
        alertLeavingRef.current?.show()
        return false;
      }
    });
    return () => {
      unblock();
    };
  }, [history, useAlert]);

  return (
    <>
      <Button
        className={twMerge("mb-4", className)}
        startIcon={BakcIcon}
        variant="text"
        onClick={history.goBack}
        {...props}
      />
      <Alert
        cancelText="Cancel"
        submitText="Yes"
        onCancel={() => backButtonClicked(false)}
        onSubmit={() => backButtonClicked(true)}
        title="Leave Without Saving?"
        closeOnBackdropClicked={false}
        ref={alertLeavingRef}
      >
        <Typography>
          You have unsaved changes in the form. This will discard your progress.
        </Typography>
      </Alert>
    </>
  );
}

BackButton.defaultProps = {
  children: 'Back',
  color: 'primary'
} as ButtonProps;

export { BackButton }
