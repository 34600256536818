
import { useHistory, useLocation, useParams } from "react-router-dom";
import { createElement, useCallback, useEffect, useState } from "react";
import SwipeableViews from "react-swipeable-views";
import {
    Button,
    Content,
    ContentHeader,
    PageTabsData,
    UnderlineTabs
} from "src/app/components";

import CampaignScreens from '../../../CampaignScreens';
import { LuckyDrawDetailPathType } from './LuckyDrawDetail.types';
import LuckyDrawDetailTabDetail from './LuckyDrawDetailTabDetail';
import LuckyDrawDetailTabParticipant from './LuckyDrawDetailTabParticipant';
import LuckyDrawDetailTabDraw from './LuckyDrawDetailTabDraw';
import LuckyDrawDetailTabWinners from './LuckyDrawDetailTabWinners';
import { PageTitle } from 'src/app/components/Layout/header/HeaderTitle';
import { BakcIcon } from "src/app/components/Icons";

const rewardTabs: PageTabsData<LuckyDrawDetailPathType>[] = [
    {
        component: LuckyDrawDetailTabDetail,
        permission: 'manage_campaign_lucky_draw.read',
        value: 'DETAIL',
        label: 'Detail',
        path: 'detail'
    },
    {
        component: LuckyDrawDetailTabParticipant,
        permission: 'manage_campaign_lucky_draw.read',
        value: 'PARTICIPANT',
        label: 'Participant List',
        path: 'participant',
    },
    {
        component: LuckyDrawDetailTabDraw,
        permission: 'manage_campaign_lucky_draw.read',
        value: 'DRAW',
        label: 'Draw',
        path: 'draw',
    },
    {
        component: LuckyDrawDetailTabWinners,
        permission: 'manage_campaign_lucky_draw.read',
        value: 'WINNERS',
        label: 'Winners',
        path: 'winners',
    },
]

export default function LuckyDrawDetailTabPage() {
    const history = useHistory();
    const location = useLocation();
    const { search } = location;
    const queryParams = new URLSearchParams(search);
    const [pageTabs, setPageTabs] = useState({
        index: 0,
        data: rewardTabs
    });
    const { id, path } = useParams<{ id: string, path: string }>();

    const renderItem = (item: PageTabsData, index: number) => (
        <div key={item.path} style={{ display: index === pageTabs.index ? 'block' : 'none' }}>
            {index === pageTabs.index && createElement(item.component, { ...item, title: item.label })}
        </div>
    );


    const baseRoute = CampaignScreens.DETAIL_LUCKY_DRAW.PATH

    const changeTab = useCallback((vitem: PageTabsData<string>, index: number) => {
        const route = baseRoute.replace(':id', id).replace(':path', vitem.path);
        history.replace(`${route}?${queryParams.toString()}`);
        setPageTabs(prev => ({ ...prev, index }));
    }, [id, history]);


    useEffect(() => {
        const targetIndex = rewardTabs.findIndex(el => el.path === path);
        if (targetIndex !== -1 && targetIndex !== pageTabs.index) {
            changeTab(rewardTabs[targetIndex], targetIndex);
        }
    }, [location.pathname]); // ✅ Fixes infinite loop

    const breadcrumbs = [
        {
            isActive: false,
            path: [CampaignScreens.LIST_LUCKY_DRAW.PATH].join(),
            title: "Lucky Draw"
        },
        { isActive: false, path: '', title: '', isSeparator: true },
    ];

    return (
        <>
            <Button
                className={"mb-4"}
                startIcon={BakcIcon}
                variant="text"
                onClick={() => history.replace("/campaign/list/lucky-draw")}
            >Back</Button>
            <Content>
                <ContentHeader title={'Detail Lucky Draw'}>
                    <UnderlineTabs
                        onChange={changeTab}
                        index={pageTabs.index}
                        items={pageTabs.data}
                    />
                </ContentHeader>
                <PageTitle description="Detail" breadcrumbs={breadcrumbs}>
                    Campaign
                </PageTitle>
                <SwipeableViews disableLazyLoading={false} index={pageTabs.index} className='px-8 py-6'>
                    {pageTabs.data.map(renderItem)}
                </SwipeableViews>
            </Content>
        </>
    );
}
