import { useLocation } from 'react-router-dom'
import { Label, MenuContent, Radio } from 'src/app/components'
import { useFilterMenuContentParticipantHook } from './FilterMenuContentParticipant.hook'

export interface FilterMenuContentParticipantProps {
  withStatusSuspended: boolean
  withStatusDeleted: boolean
  withStatusActive: boolean
}

function FilterMenuContentParticipant(props: FilterMenuContentParticipantProps) {

  const location = useLocation();
  const state = useFilterMenuContentParticipantHook()
  const statusMaster = state.master.getStatusMaster(props)

  const renderStatusItem = (item: typeof state.master.luckyDrawParticipantStatuses[0]) => {
    const mappedLabel = mapStatusLabel(item.label);
    const mappedValue = mapStatusLabel(item.value);
    return (
      <Radio
        {...state.filterContext.registerRadio({ name: 'status', value: mappedValue })}
        label={mappedLabel}
        key={item.value}
      />
    )
  }

  const mapStatusLabel = (status: string): string => {
    const statusMap: Record<string, string> = {
      Suspended: 'Suspended',
      Active: 'Active',
      Deleted: 'Deleted',
    };

    const statusKeyMap: Record<string, string> = {
      SUSPENDED: 'SUSPEND',
      ACTIVE: 'ACTIVE',
      DELETED: 'DELETED',
    };

    // Check if the status matches the event, apply mapping if true; else return as is
    if (extractEvent() === 'event') {
      return statusMap[status] || status;
    } else if (extractEvent() === 'session') {
      return statusMap[status] || statusKeyMap[status];
    }
    return status; // Return the original status if the condition is not met
  };

  // Extract the `event` part from the URL
  const extractEvent = () => {
    const pathSegments = location.pathname.split('/');
    return pathSegments[pathSegments.length - 1]; // Get the last segment as `event`
  };

  return (
    <MenuContent>
      <div>
        <Label>Status</Label>
        <div className='flex flex-wrap flex-row gap-x-4'>
          {statusMaster.map(renderStatusItem)}
        </div>
      </div>
    </MenuContent>
  )
}

FilterMenuContentParticipant.defaultProps = {
  withStatusSuspended: true,
  withStatusDeleted: true,
  withStatusActive: true,
} as FilterMenuContentParticipantProps

export { FilterMenuContentParticipant }
