import {
  twMerge
} from "tailwind-merge";
import {
  createPortal
} from "react-dom";
import {
  AnimationEvent,
  Ref,
  cloneElement,
  createElement,
  forwardRef,
  useEffect,
} from "react";

import {
  DialogSize,
  Theme
} from "./Dialog.theme";
import {
  DialogProps,
  DialogRef
} from "./Dialog.types";
import {
  DialogProvider,
  useDialogContext
} from "./Dialog.context";
import {
  DialogTitle
} from "./DialogTitle.page";

function _Dialog({
  closeOnBackdropClicked,
  dialogButton,
  classNames,
  className,
  onDismiss,
  children,
  onShow,
  onHide,
  title,
  size,
  withCloseButton,
  ...props
}: DialogProps) {

  const dialogContext = useDialogContext();

  useEffect(() => {

    function handleKeydown(this: HTMLElement, event: KeyboardEvent) {
      if (event.code === 'Escape') {
        dialogContext.hide();
      }
    }

    if (dialogContext.visible) {
      document.body.style.overflow = 'hidden';
      document.body.addEventListener('keydown', handleKeydown);
      return () => {
        document.body.style.overflow = '';
        document.body.removeEventListener('keydown', handleKeydown);
      };
    }
    return () => {
      document.body.style.overflow = '';
    }
  }, [dialogContext.visible]);

  useEffect(() => {
    if (dialogContext.dismiss) {
      onDismiss?.();
    }
  }, [dialogContext.dismiss]);

  const handleCloseDialog = (event: AnimationEvent<HTMLDivElement>) => {
    switch (event.animationName) {
      case 'opacity-to-full':
        onShow?.();
        break;

      case 'opacity-to-null':
        document.body.style.overflow = '';
        dialogContext.reset();
        onHide?.();
        break;
    }
  };

  return (
    <>
      {(!!dialogButton) && cloneElement(dialogButton, {
        onClick: () => dialogContext.show(),
        ...dialogButton.props,
        className: twMerge(dialogButton.props.className),
        key: dialogButton.key
      })}
      {(dialogContext.visible) && createPortal(
        <div
          onAnimationEnd={handleCloseDialog}
          onClick={closeOnBackdropClicked ? () => dialogContext.hide() : undefined}
          className={twMerge(
            Theme.container,
            Theme.backdrop,
            dialogContext.dismiss ? "animate-opacity-to-null" : "animate-opacity-to-full",
            classNames?.backdrop
          )}
        >
          <div className={twMerge(Theme.wrapper, DialogSize[size!], classNames?.wrapper)}>
            <div
              onClick={closeOnBackdropClicked ? (_) => _.stopPropagation() : undefined}
              id="dialog-wrapper-content"
              className={twMerge(
                Theme.content,
                dialogContext.dismiss ? "animate-modal-close" : "animate-modal-open",
                classNames?.dialog,
                className
              )}
              {...props}
            >
              {(!!title) && <DialogTitle withCloseButton={withCloseButton} className={twMerge(classNames?.title, classNames?.title)} {...{ title }} />}
              {children}
            </div>
          </div>
        </div>,
        document.body
      )}
    </>
  );
}

const Dialog = forwardRef((
  props: DialogProps,
  forwardedRef: Ref<DialogRef>
) => {
  return (
    <DialogProvider {...{ forwardedRef }}>
      {createElement(_Dialog, props)}
    </DialogProvider>
  );
});

Dialog.defaultProps = {
  closeOnBackdropClicked: false,
  onShow: () => { },
  onHide: () => { },
  size: 'md'
} as DialogProps

export { Dialog }
