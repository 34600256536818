import { useForm } from "react-hook-form";
import { LuckyDrawModel } from "../../../models/LuckyDraw.model";
import { useSafeFormDateRange } from "src/app/hooks/safe-form-date-range";
import { useGetLuckyDrawDetailGeneralInfo, useSaveLuckyDrawGeneral } from "../../../providers/LuckyDraw.provider";
import { useEffect, useState } from 'react';

const defaultForm: LuckyDrawModel = {
    category: '',
    name: '',
    directory_ids: [],
    start_date: '',
    end_date: '',
    max_day_to_print: undefined,
    max_day_to_choose_prize: undefined,
    image: '',
    tnc_eng: '',
    tnc_idn: '',
    consolation_alias: '',
    is_draft: false
}

export function useLuckyDrawForm1(id?: string) {
    const saveLuckyDrawGeneral = useSaveLuckyDrawGeneral()
    const luckyDrawDetail = useGetLuckyDrawDetailGeneralInfo(id, false)
    const form = useForm({ defaultValues: defaultForm, reValidateMode: 'onChange' })
    const masterCampaignType = [{
        value: 'LUCKY_DRAW',
        label: 'Lucky Draw'
    }]

    const minPeriod = useSafeFormDateRange({
        startDateKey: 'start_date',
        endDateKey: 'end_date',
        form,
    })

    // Watch only the specific fields we care about
    const { category_type, name, directories, start_date, end_date, max_day_to_print, max_day_to_choose_prize } = form.watch();
    // Check if at least one of the required fields is filled
    const isFormFilled = category_type || name || start_date || end_date || directories || max_day_to_print || max_day_to_choose_prize
    const [luckyDrawDetailStatus, setLuckyDrawDetailStatus] = useState('')

    useEffect(()=>{
        const categoryTypeVal = masterCampaignType.find(key=>key.value===luckyDrawDetail.data?.category);
        form.setValue('category_type', categoryTypeVal);
        form.setValue('image', luckyDrawDetail.data?.image);
        setLuckyDrawDetailStatus(luckyDrawDetail.data?.status);
    }, [luckyDrawDetail.data])

    const enabledFields = ()=> {
        const fields = [
            'end_date',
            'max_day_to_print',
            'max_day_to_choose_prize',
            'image',
            'tnc_eng',
            'tnc_idn',
            'is_draft',
            'consolation_alias'
        ]
        if (luckyDrawDetailStatus === 'ACTIVE') {
            return fields;
        }
        return [...fields, 'category', 'name', 'directory_ids', 'directories', 'start_date', 'category_type']
    }

    return ({
        saveLuckyDrawGeneral,
        luckyDrawDetail,
        form,
        masterCampaignType,
        minPeriod,
        isFormFilled,
        enabledFields
    })
}