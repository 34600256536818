import {DateObject} from 'react-multi-date-picker'

import {useFilterMenuContext} from 'src/app/components'
import { luckyDrawStatuses, luckyDrawCategories } from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.templates'
import { FilterMenuContentLuckyDrawProps } from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.types'
import { convertPeriod } from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.utils'
import {useUrlSearchParams} from 'src/app/hooks'

export function useFilterMenuContentLuckyDrawHook() {

  const [searchParams] = useUrlSearchParams<'categories' | 'status' | 'period' | 'directory_ids' | 'start_date'>()
  const defaultFilter = {
    directory_ids: searchParams.directory_ids,
    categories: searchParams.categories,
    status: searchParams.status,
    period: searchParams.period,
    start_date : searchParams.start_date,
  }

  const filterContext = useFilterMenuContext<typeof searchParams>({defaultValues: defaultFilter})

  const getStatusMaster = (params: FilterMenuContentLuckyDrawProps) => {
    return luckyDrawStatuses.filter((item) => {
      if (item.value === 'INACTIVE') {
        return params.withStatusInactive
      }
      if (item.value === 'PENDING') {
        return params.withStatusPending
      }
      if (item.value === 'ACTIVE') {
        return params.withStatusActive
      }
      if (item.value === 'DRAFT') {
        return params.withStatusDraft
      }
      return true
    })
  }

  const handleChangePeriod = (value: DateObject | DateObject[] | null) => {
    filterContext.updateDraft({period: convertPeriod(value)})
  }

  const handleChangeStartTime= (value: DateObject | DateObject[] | null) => {
    filterContext.updateDraft({start_date: convertPeriod(value)})
  }

  return ({
    filterContext,
    action: {
      handleChangePeriod,
      handleChangeStartTime
    },
    master: {
      getStatusMaster,
      luckyDrawStatuses,
      luckyDrawCategories
    },
  })
}
