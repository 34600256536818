import { ActionButton, Button, ChipStatus, Content, ContentHeader, FilterMenu, InputSearch, Paging, QueryState, QueryStateContent, Stack, Table, TableBody, TableCell, TableHead, TableRow } from 'src/app/components'
import dayjs from 'dayjs'
import CampaignScreens from '../../CampaignScreens'
import { AddRoundedIcon } from 'src/app/components/Icons'
import { usePermissions } from 'src/app/hooks/permissions-hook'
import { useLuckyDrawHooks } from './LuckyDraw.hooks'
import { LuckyDrawModel } from '../../models/LuckyDraw.model'
import { FilterMenuContentLuckyDraw } from './FilterMenuContentLuckyDraw/FilterMenuContentLuckyDraw.component'
export function translateCampaignType(type?: string) {
  if (!type) return '';
  if (type === 'LUCKY_DRAW') return 'Lucky Draw';
  return 'Sales Promotion';
}

export function LuckyDraw() {

  const permission = usePermissions()
  const state = useLuckyDrawHooks()

  const renderItem = (item: LuckyDrawModel, index: number) => {
    return (
      <TableRow key={item.id}>
        <TableCell {...{ index }} />
        <TableCell value={item?.name} />
        <TableCell value={translateCampaignType(item?.category)} />
        <TableCell renderValue={(directories: LuckyDrawModel['directories']) => {
          return (
            <div className="w-[145px]">
              {
                directories?.map((directory, index) => {
                  return (
                    <p key={directory.id} className='whitespace-pre-wrap inline'>
                      {directory.directory_name}
                      {index < directories.length - 1 && <span>, </span>}
                    </p>
                  )
                })
              }
            </div>
          )
        }}
          value={item?.directories}
        />
        <TableCell
          renderValue={(period: { start_date?: string, end_date?: string }) => {
            return (
              <>{(period.start_date && period.end_date) ? (
                <div>
                  {dayjs(period.start_date).format('DD/MM/YYYY')} - {dayjs(period.end_date).format('DD/MM/YYYY')}
                </div>
              ) : ('-')}
              </>)
          }}
          className="w-[145px]"
          value={{ start_date: item?.start_date, end_date: item?.end_date }}
        />
        <TableCell>
          <ChipStatus state={item?.status} />
        </TableCell>
        <TableCell action={true}>
        {permission.setVisibleByAccess(
          ['manage_campaign_lucky_draw.read'],
          <ActionButton
            className="mx-auto"
            variant="detail"
            to={CampaignScreens.DETAIL_LUCKY_DRAW.PATH.replace(':id', item.id!).replace(':path', 'detail')}
          />
        )}
        </TableCell>
      </TableRow>
    )
  }

  return (
    <Content>
      <ContentHeader title="List Lucky Draw">
        {permission.setVisibleByAccess(
          ['manage_campaign_lucky_draw.create'],
          <Button
            startIcon={AddRoundedIcon}
            to={'/campaign/lucky-draw/form/new'}
          >
            Add Lucky Draw
          </Button>,
        )}
      </ContentHeader>

      <Stack
        direction="row"
        justify="between"
        items="center"
        className="px-6 py-4"
      >
        <InputSearch />
        <FilterMenu>
          <FilterMenuContentLuckyDraw />
        </FilterMenu>
      </Stack>

      <QueryState query={state.luckyDrawList}>
        <div className="px-8">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell index />
                <TableCell order='name'>Campaign Name</TableCell>
                <TableCell order='category'>Campaign Type</TableCell>
                <TableCell>Mall</TableCell>
                <TableCell order="start_date" className="w-[145px]">Period</TableCell>
                <TableCell order='status'>Status</TableCell>
                <TableCell action={true} className="w-[100px]" />
              </TableRow>
            </TableHead>
            <TableBody>
              {state.luckyDrawList.data?.content?.map(renderItem)}
            </TableBody>
          </Table>
          <Paging query={state.luckyDrawList} />
        </div>
        <QueryStateContent title="List Active Lucky Draw is Empty" />
      </QueryState>
    </Content>
  )
}
