import { useFieldArray, useForm } from "react-hook-form";
import { LuckyDrawModel, LuckyDrawRuleMasterModel, PrizeRuleDetail, TransactionRule } from "../../../models/LuckyDraw.model";
import { useGetMasterStore } from "src/app/modules/directory/providers/Store.provider";
import { useGetMasterStoreCategory } from "src/app/modules/directory/providers/StoreCategory.provider";
import { StoreCategoryModel, StoreModel } from "src/app/modules/directory/models";
import { safeArray } from "src/app/utils/array-utils";
import { useEffect, useState } from "react";
import { useGetLuckyDrawRules, useSaveLuckyDrawGeneral, useSaveLuckyDrawRule } from "../../../providers/LuckyDraw.provider";
import { useUrlSearchParams } from "src/app/hooks";

export const defaultLuckyDrawPrizes: PrizeRuleDetail[] = [
    {
        prize_ids: [], // Empty array, to be populated later
        prizes: [],
        type: "GRAND_PRIZE", // Default type, change as needed
        coupon_quantity: undefined, // Default quantity
        max_coupon_per_transaction: undefined, // Default limit
    },
]
export const defaultLuckyDrawPrizeTranx: TransactionRule[] = [
    {
        minimum_transaction: undefined, // Default to 0, update when needed
        is_multiple_entry: undefined,
        payment_category_ids: [],
        payment_method_ids: [],
        payment_categories: [],
        payment_methods: [],
        is_mixable: undefined,
        prizes: defaultLuckyDrawPrizes,
    },
]

export const defaultLuckyDrawRuleValues: LuckyDrawRuleMasterModel = {
    prize_rules: [
        {
            id: undefined, // Set to undefined initially since it's optional
            merchant_category_ids: [], // Should have at least one item when filled
            merchant_ids: [], // Should have at least one item when filled
            prize_transaction_rules: defaultLuckyDrawPrizeTranx
        }
    ]
}

type DataType = Record<string, any>;
export type LengthsType = {
    prize_rules_length?: number;
    prize_rules?: {
        prize_transaction_rules_length?: number;
        prize_transaction_rules?: {
            prizes_length?: number;
        }[];
    }[];
};

export function useLuckyDrawForm3(model?: LuckyDrawModel) {
    const [searchParams, setSearchParams] = useUrlSearchParams<'step' | 'id' | 'limit' | 'page'>()
    const form = useForm({ defaultValues: defaultLuckyDrawRuleValues, reValidateMode: 'onChange' })
    const rules = useGetLuckyDrawRules(model?.id)
    const saveLuckyDrawRule = useSaveLuckyDrawRule(model?.id ?? '')
    const saveLuckyDrawGeneral = useSaveLuckyDrawGeneral();
    const { fields: storeFields, append: addStore, remove: removeStore } = useFieldArray({
        control: form.control,
        name: "prize_rules",
    });
    const [prizeRuleLength, setPrizeRuleLength] = useState<LengthsType>();
    const [storeCategories, setStoreCategories] = useState([
        { id: 0, data: [] },
    ]);

    const store = useGetMasterStore({
        directory_ids: model?.directory_ids as string[],
        limit: 2000,
        page: 1,
        statuses: ['ACTIVE'],
        // is_dropdown: true //need to implement is_dropdown to reduce data (blocked coz some logic)
    }, true)

    const store_categories = useGetMasterStoreCategory({
        limit: 2000,
        page: 1,
        statuses: ['ACTIVE'],
        is_dropdown: true
    })

    const getArrayLengths = (data: DataType): LengthsType => {
        const lengths: LengthsType = {};

        if (Array.isArray(data.prize_rules)) {
            lengths.prize_rules_length = data.prize_rules.length;
            lengths.prize_rules = data.prize_rules.map((rule) => {
                const ruleLengths: any = {};
                if (Array.isArray(rule.prize_transaction_rules)) {
                    ruleLengths.prize_transaction_rules_length = rule.prize_transaction_rules.length;
                    ruleLengths.prize_transaction_rules = rule.prize_transaction_rules.map((transaction: any) => {
                        const transactionLengths: any = {};
                        if (Array.isArray(transaction.prizes)) {
                            transactionLengths.prizes_length = transaction.prizes.length;
                        }
                        return transactionLengths;
                    });
                }
                return ruleLengths;
            });
        }
        return lengths;
    };

    useEffect(() => {
        if (model?.status === 'ACTIVE' && rules.data) {
            setPrizeRuleLength(getArrayLengths(rules.data))
        }
        form.reset(rules.data)
    }, [rules.data]);


    useEffect(() => {
        if (searchParams.limit || searchParams.page) {
            searchParams.limit = '';
            searchParams.page = '';
            setSearchParams(searchParams);
        }
    }, []);

    const addDataToRow = (rowId: number, newData: any) => {
        setStoreCategories((prev: any) => {
            const rowExists = prev.some((row: any) => row.id === rowId);
            if (rowExists) {
                return prev.map((row: any) =>
                    row.id === rowId ? { ...row, data: newData } : row
                );
            } else {
                return [...prev, { id: rowId, data: newData }];
            }
        });
    };

    const handleSyncStoreCategory = (value: StoreCategoryModel | StoreCategoryModel[], index: number) => {
        if (Array.isArray(value)) {
            const storeCategoriesData = storeCategories.find((row) => row.id === index)?.data || [];
            const isNewValue = !storeCategoriesData?.length || storeCategoriesData.length < value.length
            const stores = safeArray(form.getValues(`prize_rules.${index}.merchants`))
            if (isNewValue) {
                const lastItem = value?.at(-1)
                const masterStores = store.data?.content?.filter(
                    (_) => _.merchant_category_id === lastItem?.id,
                )
                const mergedStores = safeArray(masterStores).reduce((stack, item) => {
                    const isExist = stack.some((_) => _.id === item.id)
                    return isExist ? stack : [...stack, item]
                }, stores)
                form.setValue(`prize_rules.${index}.merchants`, mergedStores)
                form.clearErrors(`prize_rules.${index}.merchants`)
            } else {
                const syncStores = stores.reduce<StoreModel[]>((stack, item) => {
                    const isExists = value?.some((_) => _.id === item.merchant_category_id)
                    return isExists ? [...stack, item] : stack
                }, [])
                form.setValue(`prize_rules.${index}.merchants`, syncStores)
                form.clearErrors(`prize_rules.${index}.merchants`)
            }
            addDataToRow(index, value)
        }
    }

    const handleSyncStore = (value: StoreModel | StoreModel[], index: number) => {
        if (Array.isArray(value)) {
            const syncStoreCategories = value.reduce<StoreCategoryModel[]>((stack, item) => {
                const isExists = stack.some((_) => _.id === item.merchant_category_id)
                return isExists || !item.merchant_category ? stack : [...stack, item.merchant_category]
            }, [])
            form.setValue(`prize_rules.${index}.merchant_categories`, syncStoreCategories)
            form.clearErrors(`prize_rules.${index}.merchant_categories`)
            addDataToRow(index, syncStoreCategories)
        }
    }

    const handleSyncCheckedAllStore = (key: boolean, index: number) => {
        let tempDataStore: StoreModel[] = []
        let tempDataStoreCategories: StoreCategoryModel[] = []
        if (key) {
            tempDataStore = store?.data?.content ?? []
            tempDataStoreCategories = store_categories?.data?.content ?? []
        }
        form.setValue(`prize_rules.${index}.merchants`, tempDataStore)
        form.setValue(`prize_rules.${index}.merchant_categories`, tempDataStoreCategories)
        form.clearErrors(`prize_rules.${index}.merchants`)
        form.clearErrors(`prize_rules.${index}.merchant_categories`)
        addDataToRow(index, tempDataStore)
    }

    const addNewStore = () => {
        addStore(defaultLuckyDrawRuleValues.prize_rules[0])
    }

    return ({
        form,
        rules,
        storeFields,
        addNewStore,
        removeStore,
        store,
        store_categories,
        handleSyncStore,
        handleSyncStoreCategory,
        handleSyncCheckedAllStore,
        saveLuckyDrawRule,
        storeCategories,
        saveLuckyDrawGeneral,
        prizeRuleLength
    })
}