import { useForm } from 'react-hook-form'
import { useParams } from 'react-router-dom'
import CampaignScreens from 'src/app/modules/campaign/CampaignScreens'

// export interface PrintCouponModel {
//     coupon_ids: string[],
//     print_location: string,
//     consolation_prize_draw_location: string,
//     description: string
// }

export function useTabDraw() {
    const { id } = useParams<{ id: string }>()
    // const participantDetail = useGetLuckyDrawDetailParticipantDetail(participantId)

    // const breadcrumbs = [
    //     {
    //         isActive: false,
    //         path: [CampaignScreens.LIST_LUCKY_DRAW.PATH].join(),
    //         title: 'Lucky Draw',
    //     },
    //     {
    //         isActive: false,
    //         path: CampaignScreens.DETAIL_LUCKY_DRAW.PATH.replace(':id', id).replace(':path', 'draw'),
    //         title: 'Detail',
    //     },
    //     { isActive: false, path: '', title: '', isSeparator: true },
    // ]

    return {
        // breadcrumbs,
    }
}
