import {useForm} from 'react-hook-form'
import {PrizeListModel} from '../../../models/LuckyDraw.model'
import {useSafeFormDateRange} from 'src/app/hooks/safe-form-date-range'
import { useEffect, useState } from 'react'
import { useSubmitPrize } from '../../../providers/LuckyDraw.provider'

const defaultForm: PrizeListModel = {
  id: '',
  description_eng: '',
  description_idn: '',
  draw_directory_id: '',
  draw_date: '',
  type: '',
  name: '',
  image: '',
  quantity: undefined,
  directory: {},
}

export function useAddPrizeForm(props: any) {
  const submit = useSubmitPrize(props?.id as string);

  const form = useForm({defaultValues: defaultForm, reValidateMode: 'onChange'})
  const minPeriod = useSafeFormDateRange({
    startDateKey: 'draw_date',
    endDateKey: '',
    form,
  })
  const [prizeFormFlag, setPrizeFormFlag] = useState({
      isEdit: false,
      validationAvailablePrizeName: true,
    })

    const [luckyDrawDetailStatus, setLuckyDrawDetailStatus] = useState('')
  
    useEffect(()=>{
        setLuckyDrawDetailStatus(props.currentDetail?.status || '');
    }, [props.currentDetail])
  
    const enabledFields = ()=> {
      const fields = [
        'id',
        'description_eng',
        'description_idn',
        'draw_date',
        'image',
        'quantity',
      ]
      if (luckyDrawDetailStatus === 'ACTIVE' && prizeFormFlag.isEdit) {
        return fields;
      }
      return [...fields, 'draw_directory_id', 'type', 'name', 'directory']
    }
  return {
    form,
    minPeriod,
    enabledFields,
    prizeFormFlag,
    setPrizeFormFlag,
    submit,
  }
}
