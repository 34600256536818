import { useParams } from "react-router-dom";
import CampaignScreens from "../../../CampaignScreens";
import { PageTitle } from "src/app/components/Layout/header/HeaderTitle";
import LuckyDrawAddPage from "../LuckyDrawAdd.page";
import LuckyDrawEditPage from "../LuckyDrawEdit.page";
import LuckyDrawDetailTabPage from './LuckyDrawDetailTab.component';


function LuckyDrawDetailPage() {
  const params = useParams<{ id: string; path: string; }>()
  const breadcrumbs = [
    {
      isActive: false,
      path: CampaignScreens.LIST_LUCKY_DRAW.PATH,
      title: 'Lucky Draw',
    },
    { isActive: false, path: '', title: '', isSeparator: true },
  ]

  const renderPage = () => {
    if (params.path === 'form') {
      return params.id === 'new' ? <LuckyDrawAddPage /> : <LuckyDrawEditPage />;
    }
    return <LuckyDrawDetailTabPage />;
  };

  const getPageDescription = () => {
    if (params.path === "form") {
      return params.id === "new" ? "Add" : "Edit";
    }
    return "Detail";
  };

  return (
    <>
      <PageTitle
        description={getPageDescription()}
        breadcrumbs={breadcrumbs}
      >
        LuckyDraw
      </PageTitle>
      {renderPage()}
    </>
  );
}

export default LuckyDrawDetailPage;
