import { useEffect, useImperativeHandle, useRef, useState } from 'react';
import { Button, ControlLabel, Dialog, DialogContent, DialogRef, Radio, Stack } from 'src/app/components';
import { HookFormProvider, InputDatePicker, InputTextField, InputTextEditor, InputImageUpload, InputRadioGroup } from 'src/app/components/HookForm';
import InputSelectMall from 'src/app/components/HookForm/InputSelect/InputSelect.mall';
import { useAddPrizeForm } from './AddPrizePopup.hooks';
import { DateObject } from 'react-multi-date-picker';
import { PrizeListModel } from '../../../models/LuckyDraw.model';
interface AddPrizePopupProps {
  dialogRef: React.RefObject<DialogRef>;
  save?: () => void;
  cancel?: () => void;
  id?: string; // <-- example of an additional prop you expect
  [key: string]: any; // <-- allows any other props (optional)
}

const AddPrizePopup = ({ dialogRef, save, cancel, ...props }: AddPrizePopupProps) => {
  const state = useAddPrizeForm(props);
  const submitButtonClick = state.form.handleSubmit(async ({image, ...formData}) => {
    const payload = {
      ...formData,
      draw_directory_id: formData.directory?.id,
      image: image?.split('base64,', 2)[1],
    }
    hitPostAPI(payload);
  })

  const hitPostAPI = async (payload: PrizeListModel) => {
    try {
      await state.submit.mutateAsync(payload)
      clickCancel()
    } catch (error: any) {
      errorPostApi(error)
    }
  }

  const errorPostApi = (err?:any)=>{
    if (err?.response?.data?.response_schema?.response_code.includes('400')) {
      state.setPrizeFormFlag({isEdit: state.prizeFormFlag.isEdit, validationAvailablePrizeName: false})
      const modalWrapper = document.getElementById('dialog-wrapper-content');
      if (modalWrapper) {
        const modalBody = modalWrapper.querySelector('.overflow-y-scroll');
        if (modalBody) {
          modalBody.scrollTo({
            top: modalWrapper.offsetTop,
            behavior: 'smooth',
          });
        }
      }
    }
  }

  const localRef = useRef<DialogRef>(null);

  function clickCancel() {
    state.form.reset();
    localRef.current?.hide();
    cancel && cancel();
    state.setPrizeFormFlag({isEdit: false, validationAvailablePrizeName: true})
  }

  const isGrandPrize = state.form.watch('type')

  useEffect(() => {
    if (props.itemToEdit.id) {
      state.setPrizeFormFlag({isEdit: true, validationAvailablePrizeName: true})
      const entries = Object.entries(props.itemToEdit) as [keyof PrizeListModel, any][];
      entries.forEach(([key, value]) => {
        state.form.setValue(key, value);
      });
    }
  }, [props.itemToEdit, state.form]);


  useImperativeHandle(dialogRef, () => ({
    show: () => localRef.current?.show(),
    hide: () => localRef.current?.hide(),
  }), []);

  const errors = state.form.formState.errors as any;
  return (
    <Dialog
      closeOnBackdropClicked={false}
      title={(state.prizeFormFlag.isEdit? 'Edit' : 'Add')+" New Prizes"}
      size="md"
      ref={localRef}
      onHide={clickCancel}
    >
      <DialogContent className="bg-white flex flex-col">
        <>
          <HookFormProvider
            // mutation={state.saveRewards}
            enables={state.enabledFields()}
            // query={state.rewards}
            form={state.form}
          >
            <Stack className="p-6">
              <ControlLabel label="Draw Location">
                <InputSelectMall placeholder="Select draw location" isMulti={false} form={state.form} rule={{ required: true }} />
              </ControlLabel>
              <ControlLabel label="Draw Date" required={true}>
                <InputDatePicker
                  placeholder="Select draw date"
                  rule={{ required: true }}
                  name="draw_date"
                  minDate={new DateObject(props.currentDetail.end_date).add(1, 'day')}
                  form={state.form}
                />
              </ControlLabel>
              <ControlLabel label="Prizes Type" required={true}>
                <InputRadioGroup form={state.form} name="type" rule={{ required: true }}>
                  <Radio key={'grand_prize'} label="Grand Prize" value="GRAND_PRIZE" required />
                  <Radio key={'consolation'} label="Consolation" value="CONSOLATION" required />
                </InputRadioGroup>
              </ControlLabel>
              <ControlLabel label="Prizes Name">
                <InputTextField
                  placeholder="Enter prizes name"
                  rule={{ required: true }}
                  name="name"
                  maxLength={120}
                  onInput={() => {
                    state.setPrizeFormFlag({isEdit: state.prizeFormFlag.isEdit, validationAvailablePrizeName: true})
                  }}
                  error={errors?.name || state.prizeFormFlag.validationAvailablePrizeName === false}
                  helperText={(state.prizeFormFlag.validationAvailablePrizeName === false && 'Prizes name already exist in this mall, please enter another name or change draw location.') || undefined}
                />
              </ControlLabel>

              <ControlLabel label="Description (EN)">
                <div>
                  <InputTextEditor height={150} form={state.form} name="description_eng" />
                </div>
              </ControlLabel>
              <ControlLabel label="Description (ID)">
                <div>
                  <InputTextEditor height={150} form={state.form} name="description_idn" />
                </div>
              </ControlLabel>
              {(!isGrandPrize||isGrandPrize === 'GRAND_PRIZE') && (
                <ControlLabel label="Image" required={true}>
                  <div className="grid grid-cols-2 gap-8">
                    <InputImageUpload
                      placeholder="Upload Photo (JPG/PNG)"
                      maxSize={Math.pow(1024, 2) * 5}
                      accept={['.jpg', '.jpeg', '.png']}
                      rule={{ required: true }}
                      form={state.form}
                      name="image"
                    />
                    <div className="flex flex-col justify-center">
                      <ul className="text-xs text-gray-500 list-disc list-inside dark:text-gray-400">
                        <li className="mb-4">
                          Size: Max size of file is <strong>5 MB</strong>.
                        </li>
                        <li className="mb-4">
                          Photo ratio is <strong>16/9</strong>
                        </li>
                        <li>
                          File format: only allow <strong>.jpg / .png</strong>
                        </li>
                      </ul>
                    </div>
                  </div>
                </ControlLabel>
              )}
              <ControlLabel label="Prizes Qty" required={true}>
                <InputTextField
                  placeholder="Enter prizes quantity"
                  name="quantity"
                  type="number"
                  rule={{
                    required: true,
                    min: {
                      value: 1,
                      message: 'Minimal qty is 1',
                    },
                    max: {
                      value: 999,
                      message: 'Maximal qty is 999',
                    },
                  }}
                />
              </ControlLabel>

            </Stack>
            <Stack
              className={'border-t pt-4 border-neutral-200'}
              direction="row"
              gap={4}
            >
              <Button variant='outlined' onClick={clickCancel}>Cancel</Button>
              <Stack
                direction="row"
                justify='end'
                gap={4}
              >
                <Button onClick={() => submitButtonClick()} loading={state.submit.isLoading}>{state.prizeFormFlag.isEdit? 'Save':'Add'}</Button>
              </Stack>
            </Stack>
          </HookFormProvider>
        </>
      </DialogContent>
    </Dialog>
  );
};

export default AddPrizePopup;
