import { LuckyDrawForm } from "./LuckyDrawForm/LuckyDrawForm.page";

function LuckyDrawAddPage() {

  return (
    <LuckyDrawForm title="Add" />
  );
}

export default LuckyDrawAddPage;
