import {MallModel} from 'src/app/modules/directory/models'
import {useGetProfile} from 'src/app/modules/user_and_permissions/providers/User.provider'
import {safeArray} from 'src/app/utils/array-utils'
import {twMerge} from 'tailwind-merge'
import {Checkbox, Label, Stack} from 'src/app/components'
import {FilterMenuContextValue} from 'src/app/components/Feedback/FilterMenu/FilterMenu.types'

export type MenuContentMallProps = {
  filterContext?: FilterMenuContextValue<{[key: string]: any}>
  withCheckbox?: boolean
  useName?: boolean
  label?: string
}

const MenuContentMall = ({filterContext, withCheckbox = false, useName = false, label = 'Mall'}: MenuContentMallProps) => {
  const profile = useGetProfile()

  const renderMallItem = (item: MallModel) => {
    return (
      <Checkbox
        {...filterContext?.registerCheckbox({name: 'directory_ids', value: item.id})}
        className='ml-1'
        label={useName? item.directory_name : item.directory_code}
        key={item.id}
      />
    )
  }

  return (
    <div>
      <Label>{label}</Label>
      <Stack className={twMerge('max-h-[170px] overflow-auto no-scrollbar pt-1 grid grid-cols-2')}>
        {withCheckbox && (
          <div className='col-span-2'>
            <Checkbox
              {...filterContext?.registerCheckbox({name: 'directory_ids', value: 'all'})}
              className='ml-1'
              label={'All Malls'}
            />
          </div>
        )}
        {safeArray(profile?.data?.directories).map(renderMallItem)}
      </Stack>
    </div>
  )
}

export default MenuContentMall
