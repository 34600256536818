import { ChangeEvent, useImperativeHandle, useRef, useState } from 'react';
import { Button, Checkbox, ControlLabel, Dialog, DialogContent, DialogRef, QueryState, QueryStateContent, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from 'src/app/components';
import { HookFormProvider, InputSelect, InputTextEditor } from 'src/app/components/HookForm';
import { PrintCouponFormModel, useTabDetailParticipantDetail } from './TabParticipantDetail.hook';
import { ParticipantCouponModel } from 'src/app/modules/campaign/models/LuckyDraw.model';
import SelectAllCheckbox from 'src/app/components/Inputs/SelectAllCheckbox/SelectAllCheckbox.page';
import { executePrintCoupon } from 'src/app/modules/campaign/services/Campaign.service';
import { Refs } from 'src/app/utils/refs-utils';
import { LoadingIcon } from 'src/app/components/Icons';
interface PrintCouponPopupProps {
  dialogRef: React.RefObject<DialogRef>;
  onPrintDone: () => void
}

const PrintCouponPopup = ({ dialogRef, onPrintDone }: PrintCouponPopupProps) => {
  const state = useTabDetailParticipantDetail();
  const [printType, setPrintType] = useState('print')
  const loadingDialogRef = useRef<DialogRef>(null)
  const printDialogRef = useRef<DialogRef>(null);
  const confirmPrintDialogRef = useRef<DialogRef>(null);
  const executePrint = state.formPrint.handleSubmit(async ({ ...formData }) => {
    hitPostAPI({ ...formData });
  })

  const hitPostAPI = async (formValue: PrintCouponFormModel) => {
    const payload = printType === 'reprint' ? {
      member_coupon_ids: formValue.member_coupon_ids,
      print_directory_id: formValue.print_location?.id,
      draw_directory_id: formValue.draw_location?.id,
      reprint_description: formValue.reprint_description,
    } : {
      print_directory_id: formValue.print_location?.id,
      draw_directory_id: formValue.draw_location?.id,
    };
    try {
      printDialogRef.current?.hide();
      loadingDialogRef.current?.show();
      await executePrintCoupon({}, 'check');
      try {
        const checkPrintResult = await state.readyPrint.mutateAsync({ action_type: printType, payload });
        const items = checkPrintResult.data.response_output?.list?.content ?? [];
        try {
          const { data: couponOnPrint } = await executePrintCoupon({ items }, 'print');
          state.setListCouponOnPrint(couponOnPrint);
          loadingDialogRef.current?.hide();
          confirmPrintDialogRef?.current?.show();
          state.formConfirmPrint.setValue('member_coupon_ids', couponOnPrint?.map(({ id }: any) => id) ?? []);
        } catch (err) {
          loadingDialogRef.current?.hide();
          console.error("Printing Failed:", err);
          Refs.pageToast.show({
            severity: 'danger',
            message: 'Printing failed. Ensure your printer is connected and the printer hub is functioning properly.'
          });
        }
      } catch (error) {
        console.error("Failed Check Print Data:", error);
        loadingDialogRef.current?.hide();
      }
    } catch (err) {
      console.error("Printing Failed:", err);
      loadingDialogRef.current?.hide();
      Refs.pageToast.show({
        severity: 'danger',
        message: 'Printing failed. Ensure your printer is connected and the printer hub is functioning properly.'
      });
    }
  };


  const executePostPrint = state.formConfirmPrint.handleSubmit(async ({ member_coupon_ids, reason }) => {
    try {
      const data = state.listCouponOnPrint?.map(({ id }) => ({
        id,
        is_printed: member_coupon_ids?.includes(id ?? '')
      })) ?? [];
      await state.postPrint.mutateAsync({ data, failure_reason: reason });
      onPrintDone()
      confirmPrintDialogRef.current?.hide();
      state.formConfirmPrint.reset();
    } catch (error) {
      console.error("Failed Check Print Data:", error);
    }
  });

  function clickCancel() {
    state.formPrint.reset();
  }

  useImperativeHandle(dialogRef, () => ({
    show: (data) => {
      setPrintType(data?.type);
      printDialogRef.current?.show();
    },
    hide: () => {
      printDialogRef.current?.hide();
    },
  }), []);

  const checkboxChangePrint = (evt: ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    let oldVal = state.formPrint.getValues().member_coupon_ids;
    if (oldVal && !oldVal.includes(val)) {
      oldVal?.push(val)
    } else {
      oldVal = oldVal?.filter(item => item !== val);
    }
    state.formPrint.setValue('member_coupon_ids', oldVal)
  }

  const renderTableBodyPrint = (item: ParticipantCouponModel, index: number) => {
    return (
      <TableRow key={`coupon-${index}`}>
        <TableCell value={item?.coupon_code} />
        <TableCell value={item?.prize_name} />
        <TableCell action={true}>
          <Checkbox
            disabled={false}
            value={item?.id}
            key={item?.id}
            onChange={checkboxChangePrint}
          />
        </TableCell>
      </TableRow>
    )
  }

  const checkboxChangeConfirmPrint = (evt: ChangeEvent<HTMLInputElement>) => {
    const val = evt.target.value;
    let oldVal = state.checkedPrinted;
    if (oldVal && !oldVal.includes(val)) {
      oldVal?.push(val)
    } else {
      oldVal = oldVal?.filter(item => item !== val);
    }
    state.formConfirmPrint.setValue('member_coupon_ids', oldVal)
  }

  const renderTableBodyConfirmPrint = (item: ParticipantCouponModel, index: number) => {
    return (
      <TableRow key={`coupon-${index}`}>
        <TableCell value={item?.coupon_printed_code} />
        <TableCell>
          <Checkbox
            disabled={false}
            value={item?.id}
            key={item?.id}
            onChange={checkboxChangeConfirmPrint}
            checked={state.checkedPrinted?.includes(item.id!)}
          />
        </TableCell>
      </TableRow>
    )
  }

  return (
    <>
      {/* Dialog before print */}
      <Dialog
        closeOnBackdropClicked={false}
        title={printType === 'print' ? 'Print all Unprinted Coupon' : 'Reprint Coupon'}
        size="sm"
        ref={printDialogRef}
        onHide={clickCancel}
      >
        <DialogContent className="bg-white flex flex-col">
          <HookFormProvider
            form={state.formPrint}
          >
            {printType === 'reprint' && <Stack className='pb-1 !gap-1'>
              <QueryState query={state.participantReprintCouponList}>
                <Table classNames={{ container: 'pb-0 mb-0 max-h-60' }}>
                  <TableHead>
                    <TableRow>
                      <TableCell order='coupon_id'>Coupon ID</TableCell>
                      <TableCell order='prizes'>Prizes</TableCell>
                      <TableCell action={true} className="w-[100px]" />
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {state.participantReprintCouponList.data?.content?.map(renderTableBodyPrint)}
                  </TableBody>
                </Table>
                <QueryStateContent title="List Coupon is Empty" />
              </QueryState>
            </Stack>}
            <Stack className="pb-1 !gap-1">
              <ControlLabel className='grid-cols-1' label="Print Location" required={printType === 'print'} />
              {/* <InputSelectMall placeholder="Select print location" isMulti={false} form={state.formPrint} name='print_location' rule={{ required: printType === 'print' }} /> */}
              <InputSelect
                placeholder="Select print location"
                isMulti={false}
                options={state.profile?.data?.directories}
                rule={{ required: printType === 'print' }}
                form={state.formPrint}
                name="print_location"
                selectOption={(_) => ({
                  label: _.directory_name!,
                  value: _.id,
                })}
              />
            </Stack>
            {state.participantDetail.data?.is_unprinted_consolation_exist &&
              <Stack className="pb-1 !gap-1">
                <ControlLabel className='grid-cols-1' label="Consolation Prize Draw Location" required={printType === 'print'} />
                {/* <InputSelectMall placeholder="Select consolation prize draw location" isMulti={false} form={state.formPrint} name='draw_location' rule={{ required: printType === 'print' }} /> */}
                <InputSelect
                  placeholder="Select consolation prize draw location"
                  isMulti={false}
                  options={state.profile?.data?.directories}
                  rule={{ required: printType === 'print' }}
                  form={state.formPrint}
                  name="draw_location"
                  selectOption={(_) => ({
                    label: _.directory_name!,
                    value: _.id,
                  })}
                />
              </Stack>
            }

            {printType === 'reprint' && <Stack className="pb-1 !gap-1">
              <ControlLabel className='grid-cols-1' label="Description" />
              <InputTextEditor height={150} form={state.formPrint} name="reprint_description" />
            </Stack>}
            <Stack
              className={'border-t pt-4 border-neutral-200'}
              direction="row"
              gap={4}
            >
              <Stack
                direction="row"
                justify='end'
                gap={4}
              >
                <Button variant='outlined' color='danger' onClick={clickCancel}>Cancel</Button>
                <Button onClick={() => executePrint()} disabled={(printType === 'reprint' && state.couponses && state.couponses.length < 1) || (printType === 'print' && !state.formPrint.formState.isValid)} loading={false}>{printType === 'print' ? 'Print' : 'Reprint'}</Button>
              </Stack>
            </Stack>
          </HookFormProvider>
        </DialogContent>
      </Dialog>
      {/* Dialog after print */}
      <Dialog
        closeOnBackdropClicked={false}
        title="The coupon success fully printed?"
        size="sm"
        ref={confirmPrintDialogRef}
        onHide={clickCancel}
      >
        <DialogContent className="bg-white flex flex-col">
          <HookFormProvider
            form={state.formConfirmPrint}
          >
            <Stack direction='row' className='px-2 items-center'>
              <Stack className='!gap-1 flex-1'>
                <ControlLabel className='grid-cols-1' label="Select the coupon below that was successfully printed:" />
                <Typography type='bold'>Total Coupon Printed: {state.checkedPrinted?.length}/{state.listCouponOnPrint?.length}</Typography>
              </Stack>
              <SelectAllCheckbox
                label="Select All"
                onChecked={(_) => {
                  state.formConfirmPrint.setValue('member_coupon_ids', state.listCouponOnPrint?.map(item => item.id!))
                }}
                onUnChecked={() => {
                  state.formConfirmPrint.setValue('member_coupon_ids', [])
                }}
                checked={
                  (state.checkedPrinted?.length === state.listCouponOnPrint?.length)
                }
                options={state.listCouponOnPrint}
                selectOption={(_) => ({
                  label: _.category_name!,
                  value: _.id!,
                })}
              />
            </Stack>
            <Stack className="pb-1 !gap-1">
              <Table classNames={{ container: 'pb-0 mb-0 max-h-60' }}>
                <TableHead>
                  <TableRow>
                    <TableCell order='coupon_printed_code'>Print ID</TableCell>
                    <TableCell className="w-[100px]">Successfully Printed</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {state.listCouponOnPrint?.map(renderTableBodyConfirmPrint)}
                </TableBody>
              </Table>
            </Stack>
            {(state.checkedPrinted?.length !== state.listCouponOnPrint?.length) &&
              <Stack className="pb-1 !gap-1">
                <ControlLabel className='grid-cols-1' label="Reason for coupon not printed" />
                <InputTextEditor height={150} form={state.formConfirmPrint} name="reason" />
              </Stack>
            }
            <Stack
              className={'border-t pt-4 border-neutral-200'}
              direction="row"
              gap={4}
            >
              <Stack
                direction="row"
                justify='end'
                gap={4}
              >
                <Button onClick={() => executePostPrint()} loading={false}>Submit</Button>
              </Stack>
            </Stack>
          </HookFormProvider>
        </DialogContent>
      </Dialog>
      <Dialog
        closeOnBackdropClicked={false}
        size="xs"
        width='100px'
        ref={loadingDialogRef}
      >
        <DialogContent>
          <div className="flex flex-col gap-2 items-center justify-center">
            <LoadingIcon />
            <span>Printing Coupon...</span>
          </div>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PrintCouponPopup;
