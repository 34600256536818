import { useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUrlSearchParams } from "src/app/hooks";
import { LuckyDrawModel } from '../../../models/LuckyDraw.model';
import CampaignScreens from "../../../CampaignScreens";


export function useLuckyDrawForm() {
    const [searchParams, setSearchParams] = useUrlSearchParams<'step' | 'id'>()
    const params = useParams<{ id: string }>();
    const steps = ["General Info", "Prize", "Store Category", "Summary"];
    const [currentStep, setCurrentStep] = useState(1);
    const [currentId, setCurrentId] = useState('');
    const [currentDetail, setCurrentDetail] = useState({});
    const history = useHistory();

    useEffect(() => {
        if (searchParams.step) {
            setCurrentStep(+searchParams.step)
        }
        if (searchParams.id) {
            setCurrentId(searchParams.id)
        }
    }, [searchParams])

    useEffect(() => {
        setSearchParamFunc(currentStep)
        if (params.id !== 'new') {
            setCurrentId(params.id)
        }
    }, [])

    const setSearchParamFunc = (step: number, setId?: string) => {
        const newParams: Record<string, string> = { step: step.toString() };
        if (params.id === 'new' && setId) {
            newParams.id = setId;
        }
        setSearchParams(newParams);
    }

    const backward = () => {
        setSearchParamFunc(currentStep - 1)
    }

    const saveAsDraft = (key: string, detail?: LuckyDrawModel) => {
        if (key === 'STAY') {
            setCurrentId(detail?.id ?? currentId)
            setCurrentDetail(detail ?? currentDetail);
            setSearchParamFunc(currentStep, detail?.id)
        } else {
            history.replace(CampaignScreens.LIST_CAMPAIGN.PATH.replace(':path', 'lucky-draw'))
        }
    }

    const saveStep1 = (detail?: LuckyDrawModel) => {
        setCurrentId(detail?.id ?? currentId)
        setCurrentDetail(detail ?? currentDetail);
        setSearchParamFunc(2, detail?.id)
    }

    const saveStep2 = () => {
        setSearchParamFunc(3, currentId)
    }

    const saveStep3 = () => {
        setSearchParamFunc(4, currentId)
    }

    const saveStep4 = (title: string) => {
        if (title === 'Edit') {
            history.replace(CampaignScreens.DETAIL_LUCKY_DRAW.PATH.replace(':path', 'detail').replace(':id', currentId))
        } else {
            history.replace(CampaignScreens.LIST_CAMPAIGN.PATH.replace(':path', 'lucky-draw'))
        }
    }

    return ({
        steps,
        currentStep,
        currentId,
        currentDetail,
        backward,
        saveAsDraft,
        saveStep1,
        saveStep2,
        saveStep3,
        saveStep4
    })
}