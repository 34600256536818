import { useLocation } from 'react-router-dom'

import { DatePicker, Label, MenuContent, Radio, Stack } from 'src/app/components'

import MenuContentMall from 'src/app/components/Feedback/MenuContent/MenuContent.mall'
import { FilterMenuContentLuckyDrawProps } from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.types'
import { useFilterMenuContentLuckyDrawHook } from './FilterMenuContentLuckyDraw.hooks'
import { getPeriodValue } from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.utils'

function FilterMenuContentLuckyDraw(props: FilterMenuContentLuckyDrawProps) {

  const location = useLocation();
  const state = useFilterMenuContentLuckyDrawHook()
  const statusMaster = state.master.getStatusMaster(props)

  const renderCampaignTypeItem = (item: typeof state.master.luckyDrawCategories[0]) => {
    return (
      <Radio
        label={item.label}
        {...state.filterContext.registerRadio({ name: 'categories', value: item.value })}
        key={item.value}
      />
    )
  }

  const renderStatusItem = (item: typeof state.master.luckyDrawStatuses[0]) => {
    const mappedLabel = mapStatusLabel(item.label);
    const mappedValue = mapStatusLabel(item.value);
    return (
      <Radio
        {...state.filterContext.registerRadio({ name: 'status', value: mappedValue })}
        label={mappedLabel}
        key={item.value}
      />
    )
  }

  const mapStatusLabel = (status: string): string => {
    const statusMap: Record<string, string> = {
      Pending: 'Pending',
      Active: 'Active',
      Inactive: 'Inactive',
      Draft: 'Draft'
    };

    const statusKeyMap: Record<string, string> = {
      PENDING: 'PENDING',
      ACTIVE: 'ACTIVE',
      INACTIVE: 'INACTIVE',
      DRAFT: 'DRAFT'
    };

    // Check if the status matches the event, apply mapping if true; else return as is
    if (extractEvent() === 'event') {
      return statusMap[status] || status;
    } else if (extractEvent() === 'session') {
      return statusMap[status] || statusKeyMap[status];
    }
    return status; // Return the original status if the condition is not met
  };

  // Extract the `event` part from the URL
  const extractEvent = () => {
    const pathSegments = location.pathname.split('/');
    return pathSegments[pathSegments.length - 1]; // Get the last segment as `event`
  };

  return (
    <MenuContent>
      <div>
        <Label>Campaign Type</Label>
        <Stack direction="row">
          {state.master.luckyDrawCategories.map(renderCampaignTypeItem)}
        </Stack>
      </div>
      <MenuContentMall useName={true} filterContext={state.filterContext} />
      <DatePicker
        placeholder="Select date"
        onChange={state.action.handleChangePeriod}
        format="DD/MM/YYYY"
        value={getPeriodValue(state.filterContext.draft?.period)}
        range={true}
        label="Period"
      />
      <div>
        <Label>Status</Label>
        <div className='flex flex-wrap flex-row gap-x-4'>
          {statusMaster.map(renderStatusItem)}
        </div>
      </div>
    </MenuContent>
  )
}

FilterMenuContentLuckyDraw.defaultProps = {
  withStatusInactive: true,
  withStatusPending: true,
  withStatusActive: true,
  withStatusDraft: true,
  withStatus: true,
  withMall: true
} as FilterMenuContentLuckyDrawProps

export { FilterMenuContentLuckyDraw }
