import { DateObject } from "react-multi-date-picker";

export const rewardTypes = [
  {
    label: 'E-Voucher',
    value: '.DIGITAL'
  },
  {
    label: 'Physical Voucher',
    value: '.FISIK'
  }
];

export const statuses = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Pending',
    value: 'PENDING'
  },
  {
    label: 'Inactive',
    value: 'INACTIVE',
  },
];

export const luckyDrawStatuses = [
  ...statuses,
  {
    label: 'Draft',
    value: 'DRAFT'
  }
];

export const luckyDrawCategories = [
  {
    label: 'Lucky Draw',
    value: '.LUCKY_DRAW'
  },
  {
    label: 'Sales Promotion',
    value: '.SALES_PROMO'
  }
];

export const prizeListTypes = [
  {
    label: 'Grand Prize',
    value: '.GRAND_PRIZE'
  },
  {
    label: 'Consolation',
    value: '.CONSOLATION'
  }
];

export const luckyDrawParticipantStatuses = [
  {
    label: 'Active',
    value: 'ACTIVE'
  },
  {
    label: 'Suspended',
    value: 'SUSPEND'
  },
  {
    label: 'Deleted',
    value: 'DELETED',
  },
];


const dateObject = new DateObject();
export const months = dateObject.months.map((_) => ({ label: _.name, value: _.name.toLowerCase() }));
