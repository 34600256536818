import LuckyDrawDetailTabGeneralInfo from '../LuckyDrawDetail/LukcyDrawDetailTabGeneral';
import { LuckyDrawFormProps } from './LuckyDrawForm.types'
import { Button, Stack } from 'src/app/components';
import { useLuckyDrawForm4 } from './LuckyDrawForm4.hook';
import { Refs } from 'src/app/utils/refs-utils';

export function LuckyDrawForm4(props: Readonly<LuckyDrawFormProps>) {

    const state = useLuckyDrawForm4(props.id)

    function clickSubmit() {
        const { status } = props.currentDetail || {};
    
        const handleSave = () => props.save?.();
    
        if (status === 'ACTIVE' || status === 'PENDING') {
            Refs.pageToast.show({
                severity: 'success',
                message: 'Lucky Draw successfully edited.',
            });
            handleSave();
        } else {
            state.submit.mutate(props.id!, { onSuccess: handleSave });
        }
    }    

    return (
        <>
            <Stack className='px-8 pt-6'>
                <LuckyDrawDetailTabGeneralInfo data={state.luckyDrawDetail.data} useStatus={false} />
            </Stack>
            <Stack
                className={'px-8 py-6'}
                direction="row"
                gap={4}
            >
                <Stack
                    direction="row"
                    justify='end'
                    gap={4}
                >
                    <Button loading={state.submit.isLoading} variant='outlined' onClick={() => { props.backward && props.backward() }}>Previous</Button>
                    <Button loading={state.submit.isLoading} onClick={clickSubmit}>Save Lucky Draw</Button>
                </Stack>
            </Stack>
        </>
    )
}
