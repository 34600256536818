import { useParams } from 'react-router-dom';
import { useExportParticipantList, useGetLuckyDrawDetailParticipantSummary, useLuckyDrawParticipantList } from '../../../providers/LuckyDraw.provider';

export function useLuckyDrawHookDetailTabParticipant() {
  const params = useParams<{ id: string }>();

  const participantList = useLuckyDrawParticipantList(params.id);
  const exportParticipantList = useExportParticipantList(params.id);
  const participantSummary = useGetLuckyDrawDetailParticipantSummary(params.id);

  return {
    participantList,
    exportParticipantList,
    participantSummary,
    params
  };
}
