import { useGetLuckyDraw } from '../../providers/LuckyDraw.provider';

export function useLuckyDrawHooks() {

  const luckyDrawList = useGetLuckyDraw();

  return {
    luckyDrawList
  };
}
