import {useFilterMenuContext} from 'src/app/components'
import { luckyDrawParticipantStatuses } from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.templates'
import {useUrlSearchParams} from 'src/app/hooks'
import { FilterMenuContentParticipantProps } from './FilterMenuContentParticipant'

export function useFilterMenuContentParticipantHook() {

  const [searchParams] = useUrlSearchParams<'status'>()
  const defaultFilter = {
    status: searchParams.status,
  }

  const filterContext = useFilterMenuContext<typeof searchParams>({defaultValues: defaultFilter})

  const getStatusMaster = (params: FilterMenuContentParticipantProps) => {
    return luckyDrawParticipantStatuses.filter((item) => {
      if (item.value === 'SUSPEND') {
        return params.withStatusSuspended
      }
      if (item.value === 'DELETED') {
        return params.withStatusDeleted
      }
      if (item.value === 'ACTIVE') {
        return params.withStatusActive
      }
      return true
    })
  }


  return ({
    filterContext,
    action: {},
    master: {
      getStatusMaster,
      luckyDrawParticipantStatuses,
    },
  })
}
