import { LuckyDrawForm } from "./LuckyDrawForm/LuckyDrawForm.page";

function LuckyDrawEditPage() {

  return (
    <LuckyDrawForm title="Edit" />
  );
}

export default LuckyDrawEditPage;
