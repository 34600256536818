import { AddRoundedIcon } from 'src/app/components/Icons';
import { LuckyDrawFormProps } from './LuckyDrawForm.types'
import { ActionButton, Alert, Button, Content, ContentHeader, DialogRef, FilterMenu, InputSearch, Paging, QueryState, QueryStateContent, Stack, Table, TableBody, TableCell, TableHead, TableRow, Typography } from 'src/app/components';
import { usePermissions } from 'src/app/hooks/permissions-hook';
import dayjs from 'dayjs';
import { LuckyDrawModel, PrizeListModel } from '../../../models/LuckyDraw.model';
import { FilterMenuPrizeList } from './FilterMenuPrizeList/FilterMenuPrizeList.component';
import AddPrizePopup from './AddPrizePopup.component';
import { useEffect, useRef, useState } from 'react';
import { useLuckyDrawForm2Hook } from './LuckyDrawForm2.hook';
import { Refs } from 'src/app/utils/refs-utils';
import { getErrorMessage } from 'src/app/utils/api-utils';

export function translatePrizeType(type?: string) {
    if (!type) return '';
    if (type === 'GRAND_PRIZE') return 'Grand Prize';
    return 'Consolation';
}

export function LuckyDrawForm2(props: Readonly<LuckyDrawFormProps>) {

    const permission = usePermissions()
    const state = useLuckyDrawForm2Hook(props.id as string);
    const addPrizeRef = useRef<DialogRef>(null);
    const deleteAlertRef = useRef<DialogRef>(null);
    const [idToDelete, setIdToDelete] = useState('');
    const [itemToEdit, setItemToEdit] = useState({});
    const [luckyDrawDetailStatus, setLuckyDrawDetailStatus] = useState('')
    const isEdit = !!props.id && (props.currentDetail?.status === 'PENDING' || props.currentDetail?.status === 'ACTIVE')
    const saveAndExit = () => {
        props.doPreventExit && props.doPreventExit(false);
        saveAsDraft('STAY');
    }

    const popupSaveAsDraftRef = useRef<DialogRef>(null);
    function saveAsDraft(key: string) {
        popupSaveAsDraftRef.current?.hide();
        const payload: LuckyDrawModel = {
            id: props.id,
            is_draft: true,
        }
        const { status } = props.currentDetail!;
        const isctive = status === 'ACTIVE';
        if (!isctive) { hitPostAPI(payload, key) } else {
            Refs.pageToast.show({
                severity: 'success',
                message: "Lucky draw successfully edited."
            });
        }
    }

    const hitPostAPI = async (payload: LuckyDrawModel, key: string) => {
        try {
            const result = await state.saveLuckyDrawGeneral.mutateAsync(payload)
            const response_output = result.data.response_output
            const contentDetail = response_output?.detail
            if (key === 'SUBMIT') {
                props.save && props.save(contentDetail)
            } else {
                props.saveDraft && props.saveDraft(key)
            }
        } catch (error: any) {
            if (error?.response?.data?.response_schema?.response_code === 'PAKUWON-400')
                Refs.pageToast.show({
                    severity: 'danger',
                    message: `${getErrorMessage(error, true)?.en}`
                });
        }
    }

    const handleDelete = () => {
        const payload = { campaign_id: props.id as string, prize_id: idToDelete }
        state.deletePrize.mutate(payload, {
            onSuccess: () => {
                deleteAlertRef.current?.hide()
            },
        });
    };

    useEffect(() => {
        setLuckyDrawDetailStatus(props.currentDetail?.status ?? '');
    }, [props.currentDetail])

    const renderItem = (item: PrizeListModel, index: number) => {
        return (
            <TableRow key={item.id}>
                <TableCell {...{ index }} />
                <TableCell value={item?.name} />
                <TableCell value={translatePrizeType(item?.type)} />
                <TableCell value={item?.quantity} />
                <TableCell value={item?.draw_directory?.directory_name} />

                <TableCell
                    renderValue={(date: string) => {
                        return (
                            <div>
                                {dayjs(date).format('DD/MM/YYYY')}
                            </div>
                        )
                    }}
                    className="w-[145px]"
                    value={item?.draw_date}
                />
                {luckyDrawDetailStatus !== 'INACTIVE' && (
                    <TableCell action={true}>
                        <Stack direction="row" gap={2}>
                            {permission.setVisibleByAccess(
                                ['manage_campaign_lucky_draw.update'],
                                <ActionButton variant="edit" onClick={() => {
                                    const { id, description_eng, description_idn, draw_date, type, name, image, quantity } = item
                                    const itemToEdit = {
                                        directory: item.draw_directory,
                                        id,
                                        description_eng,
                                        description_idn,
                                        draw_date,
                                        type,
                                        name,
                                        image,
                                        quantity,
                                    }
                                    setItemToEdit(itemToEdit);
                                    addPrizeRef.current?.show()
                                }} />
                            )}
                            {permission.setVisibleByAccess(
                                ['manage_campaign_lucky_draw.delete'], luckyDrawDetailStatus !== 'ACTIVE' && (
                                    <ActionButton
                                        variant="delete" color="primary"
                                        onClick={() => { setIdToDelete(item?.id as string); deleteAlertRef.current?.show() }}
                                        disabled={state.prizeListList.data?.content?.length === 1 && props.currentDetail?.status === 'PENDING'}
                                    />
                                ))}
                        </Stack>
                    </TableCell>
                )}
            </TableRow>
        )
    }


    return (
        <>
            <Stack className='px-8 py-6'>
                <p className="text-xs text-gray-400 dark:text-gray-500">{props.currentDetail?.name ?? '-'}</p>
                <Content>
                    <ContentHeader title="Prize List">
                        {permission.setVisibleByAccess(
                            ['manage_campaign_lucky_draw.create'],
                            <Button
                                startIcon={AddRoundedIcon}
                                onClick={() => { setItemToEdit({}); addPrizeRef.current?.show() }}
                            >
                                Add Prize
                            </Button>,
                        )}
                    </ContentHeader>

                    <Stack
                        direction="row"
                        justify="between"
                        items="center"
                        className="px-6 py-4"
                    >
                        <InputSearch />
                        <FilterMenu>
                            <FilterMenuPrizeList />
                        </FilterMenu>
                    </Stack>

                    <QueryState query={state.prizeListList}>
                        <div className="px-8">
                            <Table>
                                <TableHead>
                                    <TableRow>
                                        <TableCell index />
                                        <TableCell>Prize Name</TableCell>
                                        <TableCell>Type</TableCell>
                                        <TableCell>Quantity</TableCell>
                                        <TableCell>Draw Location</TableCell>
                                        <TableCell className="w-[145px]">Draw Date</TableCell>
                                        <TableCell action={true} className="w-[100px]" />
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {state.prizeListList.data?.content?.map(renderItem)}
                                </TableBody>
                            </Table>
                            <Paging query={state.prizeListList} />
                        </div>
                        <QueryStateContent title="List Prize is Empty" description="Click 'Add' button to create new entry" />
                    </QueryState>
                </Content>
            </Stack>
            {permission.setVisibleByAccess(
                ['manage_campaign_lucky_draw.create'],
                <Stack
                    className={'px-8 py-6 border-t border-neutral-200'}
                    direction="row"
                    gap={4}
                >
                    <Button variant='outlined' loading={state.saveLuckyDrawGeneral.isLoading} onClick={() => { isEdit ? saveAndExit() : popupSaveAsDraftRef.current?.show() }}>{'Save' + (!isEdit ? ' as Draft' : '')}</Button>
                    <Stack
                        direction="row"
                        justify='end'
                        gap={4}
                    >
                        <Button variant='outlined' loading={state.saveLuckyDrawGeneral.isLoading} onClick={() => { props.backward && props.backward() }}>Previous</Button>
                        <Button loading={state.saveLuckyDrawGeneral.isLoading} onClick={() => { props.save && props.save(props.currentDetail) }} disabled={!state.prizeListList.data || !state.prizeListList.data?.content || state.prizeListList.data?.content?.length < 1}>Next</Button>
                    </Stack>
                </Stack>
            )}
            <AddPrizePopup dialogRef={addPrizeRef} itemToEdit={itemToEdit} cancel={() => (setItemToEdit({}))} {...props}></AddPrizePopup>
            <Alert
                cancelText="Go to List"
                submitText="Stay on page"
                onCancel={() => saveAsDraft('BACK')}
                onSubmit={() => saveAsDraft('STAY')}
                title="Save as Draft?"
                ref={popupSaveAsDraftRef}
                closeOnBackdropClicked={false}
            >
                <Typography>
                    Your progress will be saved as a draft. You can continue editing later.
                    <br />Would you like to stay on this page or return to the list?
                </Typography>
            </Alert>
            <Alert
                cancelText="Cancel"
                submitText="Delete"
                onSubmit={handleDelete}
                title="Delete Prize?"
                ref={deleteAlertRef}
                closeOnBackdropClicked={false}
            >
                <Typography>
                    Are you sure want to delete this Prize? You can create again later.
                </Typography>
            </Alert>
        </>
    )
}
