import { ActionButton, Button, Chip, ChipStatus, ChipStatusProps, Content, ContentHeader, FilterMenu, InputSearch, Menu, MenuItem, Paging, QueryState, QueryStateContent, Stack, Table, TableBody, TableCell, TableHead, TableRow } from 'src/app/components'
import { XlsIcon } from 'src/app/components/Icons'
import { usePermissions } from 'src/app/hooks/permissions-hook'
import CampaignScreens from '../../../CampaignScreens'
import { LuckyDrawParticipantModel } from '../../../models/LuckyDraw.model'
import { useLuckyDrawHookDetailTabParticipant } from './LuckyDrawDetailTabParticipant.hook'
import { FilterMenuContentParticipant } from './FilterMenuContentParticipant/FilterMenuContentParticipant'
import { Refs } from 'src/app/utils/refs-utils'
import { DateObject } from 'react-multi-date-picker'

export  const customLDDetailParticipantStatus = (status?: string): ChipStatusProps['customOpt'] => {
  if (!status) return undefined;
  const state = status.toUpperCase();
  if (state==='ACTIVE') {
    return {
      text: 'Active',
      variant: 'success'
    }
  }
  if (state==='SUSPEND') {
    return {
      text: 'Suspended',
      variant: 'danger'
    }
  }
  return {
    text: 'Deleted',
    variant: 'gray'
  }
}

export default function LuckyDrawDetailTabParticipant() {

  const permission = usePermissions()
  const state = useLuckyDrawHookDetailTabParticipant()

  const handleDownloadExport = async () => {
    const today = new Date();
    const formattedDate = today.toISOString().slice(0, 10).replace(/-/g, ''); // YYYYMMDD
    const name = `campaign_listparticipants_${formattedDate}`;
    try {
      await state.exportParticipantList.download(`${name}.xlsx`,null);
      Refs.pageToast.show({
        severity: 'success',
        message: `${name} successfully exported (.xlsx).`
      });

    } catch (error) {
      console.log("🚀 ~ handleDownloadData ~ error:", error);
    }
  };

  const renderItem = (item: LuckyDrawParticipantModel, index: number) => {
    return (
      <TableRow key={item.id}>
        <TableCell {...{ index }} />
        <TableCell value={item?.member_code} />
        <TableCell value={item?.fullname} />
        <TableCell>
          <ChipStatus state="custom" customOpt={customLDDetailParticipantStatus(item?.status)} />
        </TableCell>
        <TableCell value={item?.grand_prize_coupon_count} />
        <TableCell value={item?.consolation_coupon_count} />
        <TableCell action={true}>
          {permission.setVisibleByAccess(
            ['manage_campaign_lucky_draw.read'],
            <ActionButton
              className="mx-auto"
              variant="detail"
              to={CampaignScreens.DETAIL_LUCKY_DRAW_DETAIL_PARTICIPANT.PATH.replace(':id', state.params.id!).replace(':participantId', item.id!)}
            />
          )}
        </TableCell>
      </TableRow>
    )
  }

  const renderChips = () => {
    const { data } = state.participantSummary;
    const titles = [
      {
        key: 'total_participant',
        label: 'Total Participant'
      },
      {
        key: 'total_claimed_coupon',
        label: 'Total Claimed Coupon'
      },
      {
        key: 'total_printed_coupon',
        label: 'Total Printed Coupon'
      }
    ]
    const mappedData = titles.map((item) => {
      return {
        title: item.label,
        desc: data?.[`${item.key}`] ?? '-'
      }
    })
    return mappedData?.map(
      (chip, idx) => {
        return <Chip key={`Chip-${idx}`} variant='gray' className='bg-neutral-100 text-right rounded-md px-4 py-3'>
          <p className='text-xs text-neutral-700'>{chip.title}</p>
          <p className='text-[1rem] font-bold text-neutral-900 mt-1'>{chip.desc}</p>
        </Chip>
      }
    )
  }

  return (
    <Content>
      <ContentHeader title="Participant List">
        {renderChips()}
      </ContentHeader>

      <Stack
        direction="row"
        justify="between"
        items="center"
        className="px-6 py-4"
      >
        <Stack
          direction="row"
          justify="start"
          items="center"
        >
          <InputSearch />
          <FilterMenu>
            <FilterMenuContentParticipant />
          </FilterMenu>
        </Stack>
        <Menu
          closeOnClick={true}
          className="min-w-[160px]"
          menuButton={(
            <Button loading={false} variant="outlined">
              Export Data
            </Button>
          )}
        >
          <MenuItem startIcon={XlsIcon} onClick={handleDownloadExport}>
            Export as XLS
          </MenuItem>
        </Menu>
      </Stack>

      <QueryState query={state.participantList}>
        <div className="px-8">
          <Table>
            <TableHead>
              <TableRow>
                <TableCell index />
                <TableCell order='member_code'>Customer ID</TableCell>
                <TableCell order='fullname'>Customer Name</TableCell>
                <TableCell>Customer Status</TableCell>
                <TableCell order='grand_prize_coupon_count'>Grand Prize Coupon</TableCell>
                <TableCell order='consolation_coupon_count'>Consolation Coupon</TableCell>
                <TableCell action={true} className="w-[100px]" />
              </TableRow>
            </TableHead>
            <TableBody>
              {state.participantList.data?.content?.map(renderItem)}
            </TableBody>
          </Table>
          <Paging query={state.participantList} />
        </div>
        <QueryStateContent title="Participant List is Empty" />
      </QueryState>
    </Content>
  )
}
