import { BackButton, Button, ChipStatus, Content, ContentHeader, InputSearch, LabelItem, QueryState, Stack, Thumbnail } from 'src/app/components';
import { PageTitle } from 'src/app/components/Layout/header/HeaderTitle';
import { useTabDraw } from './LuckyDrawDetailTabDraw.hook';
import TabDrawTableContent from './TabDrawContent/TabDrawTableContent';
import TabDrawInfoContent from './TabDrawContent/TabDrawInfoContent';
import { usePermissions } from 'src/app/hooks/permissions-hook';


export default function LuckyDrawDetailTabDraw() {
  const state = useTabDraw();
  const data: any = undefined //state.participantDetail.data;
  const permission = usePermissions();

  return (
    <>
      <Content className='px-8 py-6'>
        <InputSearch />
      </Content>
      <TabDrawInfoContent />
      <Stack className="border-t pt-4 mt-4 border-neutral-200 pb-1 pr-[0.25rem]" direction="row" gap={4}>
        <Stack direction="row" justify="end" gap={4}>
          {permission.setVisibleByAccess(
            ['manage_campaign_lucky_draw.update'],
            <Button className='px-8' >Set as Winner</Button>
          )}
        </Stack>
      </Stack>
    </>
  )
}