import { useFieldArray, useFormContext, useWatch } from 'react-hook-form';
import { Button, ActionButton } from 'src/app/components';
import { InputSelect, InputTextField } from 'src/app/components/HookForm';
import { LuckyDrawTransactionProp } from './LuckyDrawTransaction.page';
import { useGetPrizeMasterList } from 'src/app/modules/campaign/providers/LuckyDraw.provider';
import { defaultLuckyDrawPrizes } from '../LuckyDrawForm3.hook';
import { LuckyDrawRuleModel, PrizeListModel, TransactionRule } from 'src/app/modules/campaign/models/LuckyDraw.model';


interface LuckyDrawPrizeProps extends LuckyDrawTransactionProp {
    ruleIndex: number
}

const LuckyDrawPrize: React.FC<LuckyDrawPrizeProps> = ({ id, storeIndex, ruleIndex, prizeRuleLength }) => {
    const form = useFormContext(); // Get the full form object
    const controlName = `prize_rules.${storeIndex}.prize_transaction_rules.${ruleIndex}.prizes`
    const { control, setValue, getValues, clearErrors, reset } = form;
    const { fields, append } = useFieldArray({
        control: control,
        name: `prize_rules.${storeIndex}.prize_transaction_rules.${ruleIndex}.prizes`
    });
    const errors = form.formState.errors as any;
    const prizeListApi = useGetPrizeMasterList(id ?? '', { page: 1, limit: 1000 })
    const prizeListContent = prizeListApi.data?.content ?? []
    const transformData = () => {
        const hasConsolation = prizeListContent.some(item => item.type === "CONSOLATION");
        const grandPrizes = prizeListContent.filter(item => item.type !== "CONSOLATION");

        return hasConsolation ? [...grandPrizes, { id: "CONSOLATION", name: "CONSOLATION" }] : grandPrizes;
    };

    const selectedPrizes = useWatch({ control, name: `prize_rules.${storeIndex}.prize_transaction_rules.${ruleIndex}.prizes` });

    // Exclude already selected prize types, except for the current row
    const getFilteredOptions = () => {
        const extractedPrizes = selectedPrizes.flatMap((item: any) => item.prizes);
        return transformData().filter(item =>
            item?.id && !extractedPrizes.some((extractedPrizes: any) => extractedPrizes?.id === item?.id)
        );
    };

    const getDisabled = (index: number): boolean => {
        const prizeRules = prizeRuleLength?.prize_rules;

        if (!prizeRules || storeIndex === undefined || !prizeRules[storeIndex]?.prize_transaction_rules) {
            return false;
        }

        const transactionRules = prizeRules[storeIndex].prize_transaction_rules;

        if (!transactionRules || ruleIndex === undefined || !transactionRules[ruleIndex]) {
            return false;
        }

        return (transactionRules[ruleIndex].prizes_length ?? 0) > index;
    };

    const handleInputChange = (value: any, index: number, key: string) => {
        setValue(`${controlName}.${index}.${key}`, value);

        // Clear the error if the value is not empty
        if (value) {
            clearErrors(`${controlName}.${index}.${key}`);
        }
    };

    const deletePrize = (delIndex: number) => {
        const currentData = getValues()
        const newValuePrizes = selectedPrizes.filter((_: any, index: number) => index !== delIndex)
        const updatedLuckyDrawData = {
            prize_rules: currentData.prize_rules.map((store: LuckyDrawRuleModel, sIndex: number) =>
                sIndex === storeIndex
                    ? {
                        ...store,
                        prize_transaction_rules: store.prize_transaction_rules!.map(
                            (rule: TransactionRule, rIndex: number) =>
                                rIndex === ruleIndex ? { ...rule, prizes: newValuePrizes } : rule
                        ),
                    }
                    : store
            ),
        };
        reset(updatedLuckyDrawData)
    }

    const handlePrizeChange = (data: PrizeListModel, index: number) => {
        if (data.id === "CONSOLATION") {
            setValue(`${controlName}.${index}.type`, 'CONSOLATION');
        } else {
            setValue(`${controlName}.${index}.prize_ids`, [data.id]);
        }
    }

    return (
        <div className="border border-gray-300 rounded-lg p-4 col-span-2">
            <table className="min-w-full">
                <thead>
                    <tr className="text-left text-gray-700">
                        <th className="p-2">Prize/Consolation</th>
                        <th className="p-2">Get Coupon</th>
                        <th className="p-2">Max Coupon</th>
                        <th className="p-2"></th>
                    </tr>
                </thead>
                <tbody>
                    {fields.map((_, index) => (
                        <tr key={'prize' + index}>
                            <td className="p-2">
                                <InputSelect
                                    onChangeValue={(data) => handlePrizeChange(data as PrizeListModel, index)}
                                    placeholder="Select Prize / Consolation"
                                    isMulti={false}
                                    options={getFilteredOptions()}
                                    rule={{
                                        required: 'Field cannot be empty',
                                    }}
                                    form={form}
                                    isDisabled={getDisabled(index)}
                                    name={`prize_rules.${storeIndex}.prize_transaction_rules.${ruleIndex}.prizes.${index}.prizes`}
                                    selectOption={(_) => ({
                                        label: _.name!,
                                        value: _.id!,
                                    })}
                                />
                            </td>

                            <td className="p-2">
                                <InputTextField
                                    placeholder="Input Cupon"
                                    name={`prize_rules.${storeIndex}.prize_transaction_rules.${ruleIndex}.prizes.${index}.coupon_quantity`}
                                    type="number"
                                    min={0}
                                    onChange={(e) => handleInputChange(e.target.value, index, 'coupon_quantity')}
                                    rule={{
                                        required: 'Field cannot be empty',
                                        min: {
                                            value: 1,
                                            message: 'Minimal qty is 1',
                                        },
                                    }}
                                    error={errors?.prize_rules?.[storeIndex]?.prize_transaction_rules?.[ruleIndex]?.prizes?.[index]?.coupon_quantity?.message}
                                    helperText={errors?.prize_rules?.[storeIndex]?.prize_transaction_rules?.[ruleIndex]?.prizes?.[index]?.coupon_quantity?.message}
                                />
                            </td>
                            <td className="p-2">
                                <InputTextField
                                    type="number"
                                    placeholder="Input Max Cupon"
                                    min={0}
                                    onChange={(e) => handleInputChange(e.target.value, index, 'max_coupon_per_transaction')}
                                    name={`prize_rules.${storeIndex}.prize_transaction_rules.${ruleIndex}.prizes.${index}.max_coupon_per_transaction`}
                                    rule={{
                                        required: 'Field cannot be empty',
                                        min: {
                                            value: getValues(`prize_rules.${storeIndex}.prize_transaction_rules.${ruleIndex}.prizes.${index}.coupon_quantity`) || 1,
                                            message: 'Max Coupon cannot be less than Get Coupon. Please enter a valid amount.',
                                        },
                                    }}
                                    error={errors?.prize_rules?.[storeIndex]?.prize_transaction_rules?.[ruleIndex]?.prizes?.[index]?.max_coupon_per_transaction?.message}
                                    helperText={errors?.prize_rules?.[storeIndex]?.prize_transaction_rules?.[ruleIndex]?.prizes?.[index]?.max_coupon_per_transaction?.message}
                                />
                            </td>
                            <td className="p-2">
                                <ActionButton disabled={getDisabled(index) || fields.length === 1} variant="delete" onClick={() => deletePrize(index)} />
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <div className="flex items-center justify-between p-4 mt-2 bg-gray-100">
                <span>Want to add more prizes?</span>
                <Button
                    variant="outlined"
                    onClick={() => append(defaultLuckyDrawPrizes)}
                >
                    Add Prize +
                </Button>
            </div>
        </div>
    );
};

export default LuckyDrawPrize;
