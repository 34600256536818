
const CampaignScreens = {

  CAMPAIGN: {
    PATH: '/campaign',
    TITLE: 'Campaign',
    TITLE_ALT: 'Campaign',
    PERMISSION: 'campaign.read',
  },
  LIST_CAMPAIGN: {
    PATH: '/campaign/list/:path',
    TITLE: 'Campaign',
    TITLE_ALT: 'Campaign',
    PERMISSION: 'campaign.read',
  },
  LIST_CAMPAIGN_ACTIVE: {
    PATH: '/campaign/list/active',
    TITLE: 'Active Campaign',
    TITLE_ALT: 'Active Campaign',
    PERMISSION: 'manage_campaign_point.read',
  },
  ADD_CAMPAIGN: {
    PATH: '/campaign/add',
    TITLE: 'Add Campaign',
    TITLE_ALT: 'Add Campaign',
    PERMISSION: 'manage_campaign_point.create',
  },
  DETAIL_CAMPAIGN: {
    PATH: '/campaign/detail/:id',
    TITLE: 'Detail',
    TITLE_ALT: 'Detail',
    PERMISSION: 'manage_campaign_point.read',
  },
  EDIT_CAMPAIGN: {
    PATH: '/campaign/edit/:id',
    TITLE: 'Edit Campaign',
    TITLE_ALT: 'Edit Campaign',
    PERMISSION: 'manage_campaign_point.update',
  },

  LIST_CAMPAIGN_HISTORY: {
    PATH: '/campaign/list/history',
    TITLE: 'History Campaign',
    TITLE_ALT: 'History Campaign',
    PERMISSION: 'history_campaign.read',
  },
  DETAIL_CAMPAIGN_HISTORY: {
    PATH: '/campaign/detail/history/:id',
    TITLE: 'History Campaign',
    TITLE_ALT: 'History Campaign',
    PERMISSION: 'history_campaign.read',
  },

  LIST_LUCKY_DRAW: {
    PATH: '/campaign/list/lucky-draw',
    TITLE: 'Lucky Draw',
    TITLE_ALT: 'Lucky Draw',
    PERMISSION: 'manage_campaign_lucky_draw.read',
  },
  DETAIL_LUCKY_DRAW: {
    PATH: '/campaign/lucky-draw/:path/:id',
    TITLE: 'Lucky Draw Detail',
    TITLE_ALT: 'Lucky Draw Detail',
    PERMISSION: 'manage_campaign_lucky_draw.read',
  },
  DETAIL_LUCKY_DRAW_DETAIL_PARTICIPANT: {
    PATH: '/campaign/lucky-draw/participant/:id/:participantId',
    TITLE: 'Lucky Draw Detail Participant',
    TITLE_ALT: 'Lucky Draw Detail Participant',
    PERMISSION: 'manage_campaign_lucky_draw.read',
  },
  // ADD_LUCKY_DRAW: {
  //   PATH: '/campaign/lucky-draw/form',
  //   TITLE: 'Add Lucky Draw',
  //   TITLE_ALT: 'Add Lucky Draw',
  //   PERMISSION: 'campaign.create',
  // },
};

export default CampaignScreens;
