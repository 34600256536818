import { Controller, useFieldArray, useFormContext, useWatch } from "react-hook-form";
import { Button, Checkbox, ControlLabel, Stack, Toggle, Typography } from "src/app/components";
import { InputFormGroup, InputSelectAsync, InputTextField } from "src/app/components/HookForm";
import { DeleteIcon } from "src/app/components/Icons";
import { getPaymentCategory } from "src/app/modules/settings/services/PaymentCategory.service";
import { getPaymentMethod } from "src/app/modules/settings/services/PaymentMethod.service";
import LuckyDrawPrize from "./LuckyDrawPrize.page";
import { defaultLuckyDrawPrizeTranx, LengthsType } from "../LuckyDrawForm3.hook";
import { LuckyDrawRuleModel } from "src/app/modules/campaign/models/LuckyDraw.model";
import { PaymentCategoryModel } from "src/app/modules/settings/models/PaymentCategory.model";
import { PaymentMethodModel } from "src/app/modules/settings/models/PaymentMethod.model";
import SelectAllCheckbox from "src/app/components/Inputs/SelectAllCheckbox/SelectAllCheckbox.page";
import { useState } from "react";

export interface LuckyDrawTransactionProp {
    storeIndex: number
    id?: string
    prizeRuleLength?: LengthsType
}

export const LuckyDrawTransaction = (props: Readonly<LuckyDrawTransactionProp>) => {
    const controlName = `prize_rules.${props.storeIndex}.prize_transaction_rules`
    const form = useFormContext(); // Get the full form object
    const { control, setValue, getValues, clearErrors, reset } = form;
    const { fields: rules, append: addRule } = useFieldArray({
        control: control,
        name: `prize_rules.${props.storeIndex}.prize_transaction_rules`,
    });

    const errors = form.formState.errors as any;
    const selectedTrx = useWatch({ control, name: controlName });
    const [store_categories_length, set_store_categories_length] = useState(0)

    const deletePrize = (delIndex: number) => {
        const currentData = getValues()
        const newValueTrx = selectedTrx.filter((_: any, index: number) => index !== delIndex)
        const updatedLuckyDrawData = {
            prize_rules: currentData.prize_rules.map((store: LuckyDrawRuleModel, sIndex: number) =>
                sIndex === props.storeIndex
                    ? {
                        ...store,
                        prize_transaction_rules: newValueTrx,
                    }
                    : store
            ),
        };
        reset(updatedLuckyDrawData)
    }

    const handlePaymentCatChange = (data: PaymentCategoryModel[], index: number) => {
        setValue(`${controlName}.${index}.payment_category_ids`, data?.map((_: PaymentCategoryModel) => _?.id));
        setValue(`${controlName}.${index}.payment_methods`, []);
        setValue(`${controlName}.${index}.payment_method_ids`, []);
    }

    const handlePaymentMedChange = (data: PaymentMethodModel[], index: number) => {
        setValue(`${controlName}.${index}.payment_method_ids`, data?.map((_: PaymentMethodModel) => _?.id));
    }

    const handleSyncCheckedAllStore = async (key: boolean, index: number) => {
        try {
            let tempDataPaymentBy: PaymentMethodModel[] = [];
            if (key) {
                const paymentCategoryIds = getValues(
                    `prize_rules.${props.storeIndex}.prize_transaction_rules.${index}.payment_category_ids`
                );
                const storeCategoriesResponse = await getPaymentMethod({
                    payment_category_ids: paymentCategoryIds,
                    statuses: ["ACTIVE"],
                    is_dropdown: true,
                    limit: 2000,
                });
                const storeCategoriesData = storeCategoriesResponse?.data?.response_output?.list?.content ?? [];
                tempDataPaymentBy = storeCategoriesData;
                set_store_categories_length(storeCategoriesData.length);
            }
            const paymentMethodIds = tempDataPaymentBy.map((item) => item.id);
            setValue(`${controlName}.${index}.payment_method_ids`, paymentMethodIds);
            setValue(`${controlName}.${index}.payment_methods`, tempDataPaymentBy);
            clearErrors(`${controlName}.${index}.payment_methods`);
        } catch (error) {
            console.error("Error in handleSyncCheckedAllStore:", error);
        }
    };

    const getDisabled = (index: number): boolean => {
        const prizeRules = props.prizeRuleLength?.prize_rules;
        if (!prizeRules || props.storeIndex === undefined || !prizeRules[props.storeIndex]) {
            return false;
        }

        return (prizeRules[props.storeIndex].prize_transaction_rules_length ?? 0) > index;
    };

    return (
        <>
            {rules.map((_, ruleIndex) => (
                <Stack key={`rules-${ruleIndex}`} className="p-6 border border border-neutral-300 rounded-lg">
                    <div className='flex items-center justify-between'>
                        <Typography variant='h5'>Transaction {ruleIndex + 1}</Typography>
                        <Button disabled={rules.length < 2 || getDisabled(ruleIndex)} startIcon={DeleteIcon} variant='text' color='danger' onClick={() => deletePrize(ruleIndex)}>
                            Delete
                        </Button>
                    </div>

                    <ControlLabel label="Minimum Transaction" required={true}>
                        <Stack direction="row" >
                            <div className="w-full">
                                <InputTextField
                                    placeholder="000000"
                                    name={`${controlName}.${ruleIndex}.minimum_transaction`}
                                    type="currency"
                                    rule={{ required: 'Field cannot be empty.' }}
                                    disabled={getDisabled(ruleIndex)}
                                    error={errors?.prize_rules?.[props.storeIndex]?.prize_transaction_rules?.[ruleIndex]?.minimum_transaction?.message}
                                    helperText={errors?.prize_rules?.[props.storeIndex]?.prize_transaction_rules?.[ruleIndex]?.minimum_transaction?.message}
                                />
                            </div>
                            <div className="min-w-[188px] h-[42px] content-center">
                                <Controller
                                    control={control}
                                    name={`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.is_multiple_entry`}
                                    defaultValue={false}
                                    disabled={getDisabled(ruleIndex)}
                                    render={({ field }) => (
                                        <Checkbox
                                            label="Valid for Multiple Entries"
                                            checked={field.value}
                                            onChange={(e) => field.onChange(e.target.checked)}
                                        />
                                    )}
                                />
                            </div>
                        </Stack>
                    </ControlLabel>

                    <ControlLabel label="Payment Category" required={true}>
                        <InputSelectAsync
                            onChangeValue={(e) => handlePaymentCatChange(e as PaymentCategoryModel[], ruleIndex)}
                            placeholder="Select Payment Category"
                            fetchApi={getPaymentCategory}
                            payload={{
                                statuses: ['ACTIVE'],
                                is_dropdown: true,
                                limit: 2000,
                            }}
                            isMulti={true}
                            form={form}
                            rule={{ required: true }}
                            disabled={getDisabled(ruleIndex)}
                            name={`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.payment_categories`}
                            selectOption={(_) => ({
                                label: _.payment_category_name!,
                                value: _.id,
                            })}
                        />
                    </ControlLabel>
                    <SelectAllCheckbox
                        label="Select All Payment by"
                        onChecked={(_) => {
                            handleSyncCheckedAllStore(true, ruleIndex)
                        }}
                        onUnChecked={() => {
                            handleSyncCheckedAllStore(false, ruleIndex)
                        }}
                        checked={
                            (form.watch(`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.payment_methods`)?.length !== 0) &&
                            (form.watch(`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.payment_methods`)?.length === store_categories_length)
                        }
                        disabled={getDisabled(ruleIndex) || form.watch(`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.payment_categories`)?.length < 1}
                        selectOption={(_) => ({
                            label: _._!,
                            value: _._!,
                        })}
                    />
                    <ControlLabel label="Payment By" required={true}>
                        <InputSelectAsync
                            onChangeValue={(e) => handlePaymentMedChange(e as PaymentMethodModel[], ruleIndex)}
                            placeholder="Select payment by"
                            fetchApi={getPaymentMethod}
                            payload={{
                                payment_category_ids: getValues(`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.payment_category_ids`),
                                statuses: ['ACTIVE'],
                                is_dropdown: true,
                                limit: 2000,
                            }}
                            isMulti={true}
                            form={form}
                            rule={{ required: true }}
                            disabled={getDisabled(ruleIndex) || form.watch(`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.payment_categories`)?.length < 1}
                            name={`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.payment_methods`}
                            selectOption={(_) => ({
                                label: _.method_name!,
                                value: _.id,
                            })}
                        />
                    </ControlLabel>

                    <ControlLabel label="Mixable Coupon" >
                        <div className="flex items-center gap-5">
                            <InputFormGroup form={form}>
                                <Toggle
                                    disabled={getDisabled(ruleIndex)}
                                    name={`prize_rules.${props.storeIndex}.prize_transaction_rules.${ruleIndex}.is_mixable`}
                                    toggle={[
                                        { label: "Yes", value: true },
                                        { label: "No", value: false }
                                    ]}
                                />
                            </InputFormGroup>
                        </div>
                    </ControlLabel>

                    <ControlLabel label="Prize" required={true} >
                        <LuckyDrawPrize
                            id={props.id}
                            storeIndex={props.storeIndex}
                            ruleIndex={ruleIndex}
                            prizeRuleLength={props.prizeRuleLength}
                        />
                    </ControlLabel>
                    {rules.length - 1 === ruleIndex && (
                        <ControlLabel label=''>
                            <Button variant='outlined' onClick={() => addRule(defaultLuckyDrawPrizeTranx)}>Add New Transaction</Button>
                        </ControlLabel>
                    )}
                </Stack>
            ))}
        </>

    );
};