import { LuckyDrawFormProps } from './LuckyDrawForm.types'
import { Alert, Button, ControlLabel, DialogRef, Divider, Stack, Typography } from 'src/app/components';
import { useLuckyDrawForm1 } from './LuckyDrawForm1.hooks';
import { HookFormProvider, InputDatePicker, InputImageUpload, InputSelect, InputTextEditor, InputTextField } from 'src/app/components/HookForm';
import { DateObject } from 'react-multi-date-picker';
import InputSelectMall from 'src/app/components/HookForm/InputSelect/InputSelect.mall';
import { usePermissions } from 'src/app/hooks/permissions-hook';
import { LuckyDrawModel } from '../../../models/LuckyDraw.model';
import { useEffect, useRef } from 'react';
import { Refs } from 'src/app/utils/refs-utils';
import { getErrorMessage } from 'src/app/utils/api-utils';

export function LuckyDrawForm1(props: Readonly<LuckyDrawFormProps>) {
    const alertSaveAsDraftRef = useRef<DialogRef>(null);
    const permission = usePermissions()
    const state = useLuckyDrawForm1(props.id)
    const isEdit = !!props.id && (['PENDING', 'ACTIVE'].includes(state.luckyDrawDetail?.data?.status ?? ''));
    useEffect(() => {
        if (state.isFormFilled) {
            props.doPreventExit && props.doPreventExit(!!state.isFormFilled)
        }
    }, [state.isFormFilled]);

    const saveAsDraft = (key: string) => {
        alertSaveAsDraftRef.current?.hide()
        const formData = state.form.getValues()
        const payload: LuckyDrawModel = {
            ...formData,
            directory_ids: formData.directories?.map(_ => _?.id),
            category: formData.category_type?.value,
            is_draft: true,
            max_day_to_choose_prize: formData.max_day_to_choose_prize && formData.max_day_to_choose_prize > 0 ? formData.max_day_to_choose_prize : undefined,
            max_day_to_print: formData.max_day_to_print && formData.max_day_to_print > 0 ? formData.max_day_to_print : undefined,
            image: formData.image?.split('base64,', 2)[1],
        }
        if (isEdit) {
            payload.id = props.id
        }
        delete payload.campaign_type
        delete payload.directories
        hitPostAPI(payload, key)
    }

    const submitButtonClick = state.form.handleSubmit(async ({
        directories,
        category_type,
        image,
        ...formData
    }) => {
        // Create the final payload, excluding bank_rewards and bank_reward_codes_file
        const payload: LuckyDrawModel = {
            ...formData,
            directory_ids: directories?.map(_ => _?.id),
            category: category_type?.value,
            image: image?.split('base64,', 2)[1],
        }
        if (isEdit) {
            payload.id = props.id
            delete payload.thumbnail_image
        }
        hitPostAPI(payload, 'SUBMIT')
    })

    const hitPostAPI = async (payload: LuckyDrawModel, key: string) => {
        try {
            const result = await state.saveLuckyDrawGeneral.mutateAsync(payload)
            const response_output = result.data.response_output
            const contentDetail = response_output?.detail
            if (key === 'SUBMIT') {
                props.save && props.save(contentDetail)
            } else {
                props.saveDraft && props.saveDraft(key, contentDetail)
            }
        } catch (error: any) {
            if (error?.response?.data?.response_schema?.response_code === 'PAKUWON-400')
                Refs.pageToast.show({
                    severity: 'danger',
                    message: `${getErrorMessage(error, true)?.en}`
                });
        }
    }
    const saveAndExit = () => {
        const { status } = state.luckyDrawDetail.data;
        const isPendingOrActive = status === 'PENDING' || status === 'ACTIVE';

        props.doPreventExit?.(false);
        isPendingOrActive ? saveWithTrigger() : saveAsDraft('STAY');
    };

    const saveWithTrigger = state.form.handleSubmit(() => saveAsDraft('STAY'));

    return (
        <>
            <HookFormProvider
                enables={state.enabledFields()}
                query={state.luckyDrawDetail}
                form={state.form}
            >
                <Stack className="p-6">
                    <ControlLabel label="Campaign Type">
                        <InputSelect
                            // onChangeValue={state.action.handleSyncStore}
                            placeholder="Select campaign type"
                            isMulti={false}
                            options={state.masterCampaignType}
                            rule={{ required: true }}
                            form={state.form}
                            name="category_type"
                            selectOption={(_) => ({
                                label: _.label,
                                value: _.value,
                            })}
                        />
                    </ControlLabel>
                    <ControlLabel label="Campaign Name">
                        <InputTextField
                            placeholder="Enter campaign name"
                            rule={{ required: true }}
                            name="name"
                            maxLength={99}
                        />
                    </ControlLabel>

                    <ControlLabel label="Mall">
                        <InputSelectMall placeholder="Type to add" isMulti={true} form={state.form} rule={{ required: true }} />
                    </ControlLabel>

                    <ControlLabel label="Periode" required={true}>
                        <Stack direction="row" items="center">
                            <InputDatePicker
                                placeholder="Select start date"
                                minDate={state.minPeriod.minStartDate}
                                rule={{ required: true }}
                                name="start_date"
                                form={state.form}
                            />
                            <Divider className="w-[50px] border-t-2" color="primary" />
                            <InputDatePicker
                                placeholder="Select end date"
                                minDate={state.minPeriod.minEndDate}
                                rule={{ required: true }}
                                name="end_date"
                                form={state.form}
                                maxDate={new DateObject().add(5, 'year')}
                            />
                        </Stack>
                    </ControlLabel>
                    <ControlLabel label="Max Days to Print After Receipt Approved" required={true}>
                        <InputTextField
                            placeholder="Enter max days to print after"
                            name="max_day_to_print"
                            type="number"
                            suffix='Days'
                            rule={{
                                required: true,
                                min: {
                                    value: 1,
                                    message: 'Minimum day is 1',
                                },
                                max: {
                                    value: 999,
                                    message: 'Maximum day is 999',
                                },
                            }}
                        />
                    </ControlLabel>
                    <ControlLabel label="Max Days to select coupon after Snap Receipt " required={true}>
                        <InputTextField
                            placeholder="Enter max days to print after"
                            name="max_day_to_choose_prize"
                            type="number"
                            suffix='Days'
                            rule={{
                                required: true,
                                min: {
                                    value: 1,
                                    message: 'Minimum day is 1',
                                },
                                max: {
                                    value: 999,
                                    message: 'Maximum day is 999',
                                },
                            }}
                        />
                    </ControlLabel>
                    <ControlLabel label="Consolation Name in PG Card Apps">
                        <InputTextField
                            placeholder="Enter consolation name"
                            rule={{ required: false }}
                            name="consolation_alias"
                            maxLength={99}
                        />
                    </ControlLabel>
                    <ControlLabel label="Campaign Image" required={true}>
                        <div className="grid grid-cols-2 gap-8">
                            <InputImageUpload
                                placeholder="Upload Photo (JPG/PNG)"
                                maxSize={Math.pow(1024, 2) * 5}
                                accept={['.jpg', '.jpeg', '.png']}
                                rule={{ required: true }}
                                form={state.form}
                                name="image"
                            />
                            <div className="flex flex-col justify-center">
                                <ul className="text-xs text-gray-500 list-disc list-inside dark:text-gray-400">
                                    <li className="mb-4">
                                        Size: Max size of file is <strong>5 MB</strong>.
                                    </li>
                                    <li className="mb-4">
                                        Photo ratio is <strong>16:9</strong>
                                    </li>
                                    <li>
                                        File format: only allow <strong>.jpg / .png</strong>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </ControlLabel>
                    <ControlLabel label="Terms & Conditions (EN)">
                        <div>
                            <InputTextEditor height={150} form={state.form} name="tnc_eng" placeholder="Enter Event Description" />
                        </div>
                    </ControlLabel>
                    <ControlLabel label="Terms & Conditions (ID)">
                        <div>
                            <InputTextEditor height={150} form={state.form} name="tnc_idn" placeholder="Enter Event Description" />
                        </div>
                    </ControlLabel>

                </Stack>
                <Stack
                    className={'px-8 py-6 border-t border-neutral-200'}
                    direction="row"
                    gap={4}
                >
                    {permission.setVisibleByAccess(
                        ['manage_campaign_lucky_draw.create'],
                        <Button disabled={!state.isFormFilled} variant='outlined' loading={state.saveLuckyDrawGeneral.isLoading} onClick={() => { isEdit ? saveAndExit() : alertSaveAsDraftRef.current?.show() }}>{'Save' + (!isEdit ? ' as Draft' : '')}</Button>,
                    )}

                    <Stack
                        direction="row"
                        justify='end'
                        gap={4}
                    >
                        {permission.setVisibleByAccess(
                            ['manage_campaign_lucky_draw.create'],
                            <Button disabled={!state.isFormFilled} loading={state.saveLuckyDrawGeneral.isLoading} onClick={() => submitButtonClick()}>Next</Button>,
                        )}

                    </Stack>
                </Stack>
            </HookFormProvider>
            <Alert
                cancelText="Go to List"
                submitText="Stay on page"
                onCancel={() => saveAsDraft('BACK')}
                onSubmit={() => saveAsDraft('STAY')}
                title="Save as Draft?"
                ref={alertSaveAsDraftRef}
                closeOnBackdropClicked={false}
            >
                <Typography>
                    Your progress will be saved as a draft. You can continue editing later.
                    <br />Would you like to stay on this page or return to the list?
                </Typography>
            </Alert>
        </>
    )
}
