import Stepper from 'src/app/components/Display/Stepper/Stepper.page'
import { LuckyDrawFormProps } from './LuckyDrawForm.types'
import { useLuckyDrawForm } from './LuckyDrawForm.hook';
import { BackButton, Content, ContentHeader } from 'src/app/components';
import { LuckyDrawForm1 } from './LuckyDrawForm1.page';
import { LuckyDrawForm2 } from './LuckyDrawForm2.page';
import { LuckyDrawForm3 } from './LuckyDrawForm3.page';
import { LuckyDrawForm4 } from './LuckyDrawForm4.page';
import { useState } from 'react';
import { PageTitle } from 'src/app/components/Layout/header/HeaderTitle';
import CampaignScreens from '../../../CampaignScreens';

export function LuckyDrawForm(props: Readonly<LuckyDrawFormProps>) {
    const state = useLuckyDrawForm()
    const [preventExit, setPreventExit] = useState(false)
    const breadcrumbs = [
        {
            isActive: false,
            path: [CampaignScreens.LIST_LUCKY_DRAW.PATH].join(),
            title: "Lucky Draw"
        },
        { isActive: false, path: '', title: '', isSeparator: true },
    ];

    return (
        <>
            <BackButton className={state.currentStep !== 1 ? 'hidden' : ''} useAlert={preventExit}>Back to list</BackButton>
            <Content className="min-h-0">
                <ContentHeader title={`${props.title} Lucky Draw`} />
                <PageTitle description={props.title} breadcrumbs={breadcrumbs}>
                    Campaign
                </PageTitle>
                <Stepper classNames='py-4 border-b' steps={state.steps} currentStep={state.currentStep} />
                {state.currentStep === 1 && <LuckyDrawForm1 id={state.currentId} save={state.saveStep1} saveDraft={state.saveAsDraft} doPreventExit={setPreventExit} />}
                {state.currentStep === 2 && <LuckyDrawForm2 id={state.currentId} save={state.saveStep2} saveDraft={state.saveAsDraft} doPreventExit={setPreventExit} backward={state.backward} currentDetail={state.currentDetail} />}
                {state.currentStep === 3 && <LuckyDrawForm3 id={state.currentId} save={state.saveStep3} saveDraft={state.saveAsDraft} doPreventExit={setPreventExit} backward={state.backward} currentDetail={state.currentDetail} />}
                {state.currentStep === 4 && <LuckyDrawForm4 id={state.currentId} save={() => state.saveStep4(props.title!)}  backward={state.backward} currentDetail={state.currentDetail} />}
            </Content>
        </>
    )
}
