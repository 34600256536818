import { useLuckyDrawDetailTabDetail } from './LuckyDrawDetailTabDetail.hook'
import { Alert, Button, DialogRef, QueryState, Stack, Typography } from 'src/app/components'
import { LuckyDrawDetailModel } from '../../../models/LuckyDraw.model'
import { useRef } from 'react'
import { useHistory, useParams } from 'react-router-dom'
import { usePermissions } from 'src/app/hooks/permissions-hook'
import LuckyDrawDetailTabGeneralInfo from './LukcyDrawDetailTabGeneral'


function LuckyDrawDetailTabDetail() {
  const state = useLuckyDrawDetailTabDetail()
  const data: LuckyDrawDetailModel = state.generalInfo.data
  const alertDeleteRef = useRef<DialogRef>(null);
  const { id, path } = useParams<{ id: string, path: string }>();
  const history = useHistory();
  const permission = usePermissions();

  const handleDelete = () => {
    state.deleteLuckyDraw.mutate(id, {
      onSuccess: () => {
        // ✅ Close the page (navigate away) on success
        history.replace("/campaign/list/lucky-draw");
      },
    });
  };

  return (
    <>
      <QueryState query={state.generalInfo}>
        <div>
          <LuckyDrawDetailTabGeneralInfo data={data} />
          {path === 'detail' && data?.status !== 'INACTIVE' && (
            <Stack className="border-t pt-4 mt-4 border-neutral-200 pb-1 pr-[0.25rem]" direction="row" gap={4}>
              <Stack direction="row" justify="end" gap={4}>
                {permission.setVisibleByAccess(
                  ['manage_campaign_lucky_draw.delete'], data?.status !== 'ACTIVE' && (
                    <Button variant="outlined" color="danger" onClick={() => alertDeleteRef.current?.show()}>
                      Delete Lucky Draw
                    </Button>
                  ))}
                {permission.setVisibleByAccess(
                  ['manage_campaign_lucky_draw.update'],
                  <Button className='px-8' to={`/campaign/lucky-draw/form/${id}`}>Edit</Button>
                )}
              </Stack>
            </Stack>
          )}
        </div>
      </QueryState>
      <Alert
        cancelText="Cancel"
        submitText="Delete"
        onSubmit={handleDelete}
        title="Delete Lucky Draw?"
        ref={alertDeleteRef}
        closeOnBackdropClicked={false}
      >
        <Typography>
          Are you sure you want to delete this lucky draw? This action cannot be undone, and all related data will be lost.
        </Typography>
      </Alert>
    </>
  )
}

export default LuckyDrawDetailTabDetail
