import { useMutation, useQuery, useQueryClient } from "@tanstack/react-query";
import { AxiosError, AxiosResponse } from "axios";
import { createSelector } from "reselect";

import { BaseResponse, Pagination, QueryModel, Response, ResponseFieldError, ResponseList } from "src/app/models/api.types";

import { useApiParams, useFileDownload } from "src/app/hooks";
import { URL_CAMPAIGN_LUCKY_DRAW, URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT, URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_COUPONS, URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_DETAIL, URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_SUMMARY, URL_CAMPAIGN_LUCKY_DRAW_PRIZES, deleteLuckyDraw, deleteLuckyDrawPrize, getExportListParticipant, getLuckyDrawDetail, getLuckyDrawList, getLuckyDrawParticipantCouponList, getLuckyDrawParticipantList, getLuckyDrawParticipantSummary, getLuckyDrawPrizeList, getLuckyDrawRule, getParticipantDetail, saveLuckyDrawGeneral, saveLuckyDrawRule, submitPrintCoupon, submitPrize, submitSummaryLuckyDraw, submitPostPrintCoupon } from "../services/Campaign.service";

import { LuckyDrawDetailModel, LuckyDrawModel, LuckyDrawParticipantModel, LuckyDrawRuleMasterModel, LuckyDrawRuleModel, ParticipantCouponModel, ParticipantDetailModel, ParticipantSummaryModel, PrizeListModel } from '../models/LuckyDraw.model';
import { defaultLuckyDrawPrizes, defaultLuckyDrawPrizeTranx, defaultLuckyDrawRuleValues } from "../page/Campaign/LuckyDrawForm/LuckyDrawForm3.hook";

const selectList = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<any[]>>>) => state.data,
  (data: BaseResponse<Pagination<any[]>>) => data.response_output?.list
);
const selectDetail = createSelector(
  (state: AxiosResponse<BaseResponse<Response<any>>>) => state.data,
  (data: BaseResponse<Response<any>>) => data.response_output?.detail
);

export function useGetLuckyDraw(param?: QueryModel) {

  const defaultParams = {
    directory_ids: '',
    period: '',
    categories: ''
  };
  const [_params] = useApiParams({ defaultValues: defaultParams });
  const params = { ...param, ..._params };

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<LuckyDrawModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<LuckyDrawModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_CAMPAIGN_LUCKY_DRAW, params],
    queryFn: (_) => getLuckyDrawList(params, _.signal),
    select: selectList,
  });
}

export function useGetPrizeList(campaign_id: string, param?: QueryModel) {

  const defaultParams = {
    directory_ids: '',
    draw_date: '',
    types: ''
  };
  const [_params] = useApiParams({ defaultValues: defaultParams });
  const params = { ...param, ..._params };

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<PrizeListModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<PrizeListModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id), params],
    queryFn: (_) => getLuckyDrawPrizeList(params, campaign_id, _.signal),
    select: selectList,
  });
}

export function useGetPrizeMasterList(campaign_id: string, param?: QueryModel) {
  return useQuery<
    AxiosResponse<BaseResponse<Pagination<PrizeListModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<PrizeListModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id), param],
    queryFn: (_) => getLuckyDrawPrizeList(param, campaign_id, _.signal),
    select: selectList,
  });
}

export const useSubmitPrize = (campaign_id: string) => {
  const refetchList = useRefetchFeatured(URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id));
  return useMutation<
    AxiosResponse<BaseResponse<Response<PrizeListModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    any
  >({
    mutationKey: [`${URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id)}`],
    mutationFn: (payload) => submitPrize(campaign_id, payload),
    onSuccess: refetchList
  })
}

export const useDeletePrize = (campaign_id: string) => {
  const refetchList = useRefetchFeatured(URL_CAMPAIGN_LUCKY_DRAW_PRIZES(campaign_id));
  return useMutation<
    AxiosResponse<BaseResponse<Response<PrizeListModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    { campaign_id: string, prize_id: string } // Pass `prize_id` as a variable when calling mutate
  >({
    mutationFn: ({ campaign_id, prize_id }) => deleteLuckyDrawPrize(campaign_id, prize_id),
    onSuccess: refetchList
  });
}

function useRefetchFeatured(queryKey: any) {
  const queryClient = useQueryClient()

  return () => {
    queryClient.invalidateQueries({ queryKey })
  }
}

// save campaign
export function useSaveLuckyDrawGeneral() {

  return useMutation<
    AxiosResponse<BaseResponse<Response<LuckyDrawModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    LuckyDrawModel
  >({
    mutationKey: [URL_CAMPAIGN_LUCKY_DRAW],
    mutationFn: saveLuckyDrawGeneral
  });
}

export const useSaveLuckyDrawRule = (campaign_id: string) => {
  return useMutation<
    AxiosResponse<BaseResponse<Pagination<PrizeListModel[]>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    PrizeListModel[]
  >({
    mutationKey: [`${URL_CAMPAIGN_LUCKY_DRAW}/${campaign_id}/rules`],
    mutationFn: (payload) => saveLuckyDrawRule(campaign_id, payload),
  })
}

export function useSubmitSummaryLuckyDraw() {
  return useMutation<
    AxiosResponse<BaseResponse<Response<LuckyDrawModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    string
  >({
    mutationFn: (campaign_id) => submitSummaryLuckyDraw(campaign_id)
  });
}

export function useDeleteLuckyDraw() {
  return useMutation<
    AxiosResponse<BaseResponse<Response<LuckyDrawModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    string // Pass `id` as a variable when calling mutate
  >({
    mutationFn: (id) => deleteLuckyDraw(id), // Correct function reference
  });
}


export function useGetLuckyDrawDetailGeneralInfo(id?: string, is_full_info?: boolean) {
  return useQuery<
    AxiosResponse<BaseResponse<Response<LuckyDrawDetailModel>>>,
    AxiosError<BaseResponse<Response<LuckyDrawDetailModel>>>,
    any | undefined
  >({
    keepPreviousData: false,
    queryKey: [`${URL_CAMPAIGN_LUCKY_DRAW}/${id}`],
    queryFn: (_) => getLuckyDrawDetail(id ?? '', { is_full_info }, _.signal),
    select: selectDetail,
    enabled: !!id
  });
}

const selectLuckyDrawRules = createSelector(
  (state: AxiosResponse<BaseResponse<Pagination<LuckyDrawRuleModel[]>>>) => state.data,
  (data: BaseResponse<Pagination<LuckyDrawRuleModel[]>>) => {
    const content = data.response_output?.list?.content && data.response_output?.list?.content?.length > 0 ? data.response_output?.list?.content : defaultLuckyDrawRuleValues.prize_rules;
    if (content[0].prize_transaction_rules && content[0].prize_transaction_rules?.length > 0) {
      content[0].prize_transaction_rules = content[0].prize_transaction_rules.map((trx) => ({
        ...trx,
        prizes: (trx.prizes?.length ? trx.prizes : defaultLuckyDrawPrizes).map((item) => {
          let updatedPrizes;
          if (item.type === "CONSOLATION") {
            updatedPrizes = { id: "CONSOLATION", name: "CONSOLATION" };
          } else {
            updatedPrizes = item.prizes ? (item.prizes as any)[0] : undefined;
          }
          return {
            ...item,
            prizes: updatedPrizes,
          }
        })
      }));

    } else {
      content[0].prize_transaction_rules = defaultLuckyDrawPrizeTranx
    }
    return { prize_rules: content } as LuckyDrawRuleMasterModel
  }

);
export function useGetLuckyDrawRules(id?: string) {
  return useQuery<
    AxiosResponse<BaseResponse<Pagination<LuckyDrawRuleModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    LuckyDrawRuleMasterModel | undefined
  >({
    keepPreviousData: true,
    queryKey: [`${URL_CAMPAIGN_LUCKY_DRAW}/${id}/rules`],
    queryFn: (_) => getLuckyDrawRule(id ?? '', _.signal),
    select: selectLuckyDrawRules,
    enabled: !!id,
  });
}

export function useLuckyDrawParticipantList(lucky_draw_id: string, param?: QueryModel) {
  const defaultParams = {
    directory_ids: '',
    period: '',
    categories: ''
  };
  const [_params] = useApiParams({ defaultValues: defaultParams });
  const params = { ...param, ..._params };

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<LuckyDrawParticipantModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<LuckyDrawParticipantModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT(lucky_draw_id), params],
    queryFn: (_) => getLuckyDrawParticipantList(lucky_draw_id, params, _.signal),
    select: selectList,
  });
}

// useExportParticipantList
export function useExportParticipantList(campaign_id:string) {
  const defaultValues = {
    search:'',
    sort:'',
    order: ''
  };
  const [_params] = useApiParams({
    defaultValues,
  });
  const params = {
    ..._params,
    page: undefined,
    limit: undefined
  };

  return useFileDownload<unknown>({
    mutationKey: [URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT(campaign_id)],
    mutationFn: (_) => getExportListParticipant(campaign_id, { ...params })
  });
}

export function useGetLuckyDrawDetailParticipantSummary(lucky_draw_id: string) {
  return useQuery<
    AxiosResponse<BaseResponse<Response<ParticipantSummaryModel>>>,
    AxiosError<BaseResponse<Response<ParticipantSummaryModel>>>,
    any | undefined
  >({
    keepPreviousData: false,
    queryKey: [URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_SUMMARY(lucky_draw_id)],
    queryFn: (_) => getLuckyDrawParticipantSummary(lucky_draw_id, _.signal),
    select: selectDetail,
    enabled: !!lucky_draw_id
  });
}

export function useGetLuckyDrawDetailParticipantDetail(lucky_draw_id: string, participant_id: string) {
  return useQuery<
    AxiosResponse<BaseResponse<Response<ParticipantDetailModel>>>,
    AxiosError<BaseResponse<Response<ParticipantDetailModel>>>,
    ParticipantDetailModel | undefined
  >({
    keepPreviousData: false,
    queryKey: [URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_DETAIL(lucky_draw_id, participant_id)],
    queryFn: (_) => getParticipantDetail(lucky_draw_id, participant_id, _.signal),
    select: selectDetail,
    enabled: !!participant_id && !!lucky_draw_id
  });
}

export function useLuckyDrawParticipantCouponList(lucky_draw_id: string, participant_id: string, param?: QueryModel) {

  const [_params] = useApiParams();
  const params = { ...param, ..._params };

  return useQuery<
    AxiosResponse<BaseResponse<Pagination<ParticipantCouponModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<ParticipantCouponModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_COUPONS(lucky_draw_id, participant_id), params],
    queryFn: (_) => getLuckyDrawParticipantCouponList(lucky_draw_id, participant_id, params, _.signal),
    select: selectList,
  });
}

export function useLuckyDrawParticipantReprintCouponList(lucky_draw_id: string, participant_id: string, param?: QueryModel) {
  return useQuery<
    AxiosResponse<BaseResponse<Pagination<ParticipantCouponModel[]>>>,
    AxiosError<BaseResponse<Response<any>>>,
    ResponseList<ParticipantCouponModel[]> | undefined
  >({
    keepPreviousData: true,
    queryKey: [URL_CAMPAIGN_LUCKY_DRAW_PARTICIPANT_COUPONS(lucky_draw_id, participant_id), param],
    queryFn: (_) => getLuckyDrawParticipantCouponList(lucky_draw_id, participant_id, param, _.signal),
    select: selectList,
  });
}

export const useSubmitPrintCoupon = (lucky_draw_id: string, participant_id: string) => {
  return useMutation<
    AxiosResponse<BaseResponse<Pagination<ParticipantCouponModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    any
  >({
    mutationFn: ({ action_type, payload }) => submitPrintCoupon(lucky_draw_id, participant_id, action_type, payload),
  })
}

export const useSubmitPostPrintCoupon = (lucky_draw_id: string, participant_id: string) => {
  return useMutation<
    AxiosResponse<BaseResponse<Pagination<ParticipantCouponModel>>>,
    AxiosError<BaseResponse<ResponseFieldError>>,
    any
  >({
    mutationFn: (payload) => submitPostPrintCoupon(lucky_draw_id, participant_id, payload),
  })
}