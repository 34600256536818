import { DatePicker, Label, MenuContent, Radio, Stack } from 'src/app/components'

import MenuContentMall from 'src/app/components/Feedback/MenuContent/MenuContent.mall'
import { FilterMenuContentProps } from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.types'
import { useFilterMenuPrizeListHook } from './FilterMenuPrizeList.hooks'

function FilterMenuPrizeList(props: FilterMenuContentProps) {

  const state = useFilterMenuPrizeListHook()

  const renderPrizeListTypeItem = (item: typeof state.master.prizeListTypes[0]) => {
    return (
      <Radio
        label={item.label}
        {...state.filterContext.registerRadio({ name: 'types', value: item.value })}
        key={item.value}
      />
    )
  }


  return (
    <MenuContent>
      <div>
        <Label>Type</Label>
        <Stack direction="row">
          {state.master.prizeListTypes.map(renderPrizeListTypeItem)}
        </Stack>
      </div>
      <MenuContentMall label='Draw Location' useName={true} filterContext={state.filterContext} />
      <DatePicker
        placeholder="Select date"
        onChange={state.action.handleDrawDate}
        format="DD/MM/YYYY"
        value={state.filterContext.draft?.draw_date}
        range={false}
        multiple={false}
        label="Draw Date"
      />
    </MenuContent>
  )
}

// FilterMenuPrizeList.defaultProps = {
//   withStatusInactive: true,
//   withStatusPending: true,
//   withStatusActive: true,
//   withStatusDraft: true,
//   withStatus: true,
//   withMall: true
// } as FilterMenuContentProps

export { FilterMenuPrizeList }
