import {DateObject} from 'react-multi-date-picker'

import {useFilterMenuContext} from 'src/app/components'
import {prizeListTypes} from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.templates'
import {convertPeriod} from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.utils'
import {useUrlSearchParams} from 'src/app/hooks'

export function useFilterMenuPrizeListHook() {
  const [searchParams] = useUrlSearchParams<'types' | 'directory_ids' | 'draw_date'>()
  const defaultFilter = {
    directory_ids: searchParams.directory_ids,
    types: searchParams.types,
    draw_date: searchParams.draw_date,
  }

  const filterContext = useFilterMenuContext<typeof searchParams>({defaultValues: defaultFilter})

  const handleDrawDate = (value: DateObject | DateObject[] | null) => {
    filterContext.updateDraft({draw_date: convertPeriod(value)})
  }

  return {
    filterContext,
    action: {
      handleDrawDate,
    },
    master: {
      prizeListTypes,
    },
  }
}
