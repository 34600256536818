import {useLocation, useParams} from 'react-router-dom'
import {useDeleteLuckyDraw, useGetLuckyDrawDetailGeneralInfo} from '../../../providers/LuckyDraw.provider'

export function useLuckyDrawDetailTabDetail() {
  const location = useLocation()
  const params = useParams<{ id: string }>();
  const generalInfo = useGetLuckyDrawDetailGeneralInfo(params.id, true)
  const deleteLuckyDraw = useDeleteLuckyDraw()

  return {
    generalInfo,
    location,
    deleteLuckyDraw
  }
}
