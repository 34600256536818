import { HookFormProvider, InputSelect } from 'src/app/components/HookForm';
import { LuckyDrawFormProps } from './LuckyDrawForm.types'
import { Alert, Button, ControlLabel, DialogRef, Stack, Typography } from 'src/app/components';
import { useLuckyDrawForm3 } from './LuckyDrawForm3.hook';
import { usePermissions } from 'src/app/hooks/permissions-hook';
import { useRef } from 'react';
import SelectAllCheckbox from 'src/app/components/Inputs/SelectAllCheckbox/SelectAllCheckbox.page';
import { DeleteIcon } from 'src/app/components/Icons';
import { LuckyDrawTransaction } from './LuckyDrawForm3Child/LuckyDrawTransaction.page';
import { LuckyDrawModel, LuckyDrawRuleModel, PrizeRuleDetail, TransactionRule } from '../../../models/LuckyDraw.model';
import { Refs } from 'src/app/utils/refs-utils';
import { getErrorMessage } from 'src/app/utils/api-utils';

export function LuckyDrawForm3(props: Readonly<LuckyDrawFormProps>) {
    const alertSaveAsDraftRef = useRef<DialogRef>(null);
    const state = useLuckyDrawForm3(props.currentDetail)
    const permission = usePermissions()
    const isEdit = !!props.id && (props.currentDetail?.status === 'PENDING' || props.currentDetail?.status === 'ACTIVE')
    const saveAndExit = () => {
        const { status } = props.currentDetail!;
        const isPendingOrActive = status === 'PENDING' || status === 'ACTIVE';
        props.doPreventExit?.(false);
        isPendingOrActive ? saveWithTrigger() : saveAsDraft('STAY');
    };

    const saveWithTrigger = state.form.handleSubmit(() => saveAsDraft('STAY'));

    const transformLuckyDrawData = (formData: any, isDraft = false) => ({
        prize_rules: formData.prize_rules.map(({ merchant_categories, merchants, prize_transaction_rules, ...store }: LuckyDrawRuleModel) => ({
            ...store,
            is_draft: isDraft,
            merchant_ids: merchants?.map(m => m?.id) ?? [],
            merchant_category_ids: merchant_categories?.map(c => c?.id) ?? [],
            prize_transaction_rules: (prize_transaction_rules ?? []).map(({ payment_categories, payment_methods, minimum_transaction, prizes, is_mixable, is_multiple_entry, ...rule }: TransactionRule) => ({
                ...rule,
                is_draft: isDraft,
                minimum_transaction: minimum_transaction ? +minimum_transaction : 0,
                is_mixable: is_mixable ?? false,
                is_multiple_entry: is_multiple_entry ?? false,
                prizes: (prizes ?? []).map(({ prizes, ...prize }: PrizeRuleDetail) => ({
                    ...prize,
                    is_draft: isDraft,
                })),
            })),
        })),
    });

    const saveAsDraft = (key: string) => {
        alertSaveAsDraftRef.current?.hide();
        const formData = state.form.getValues();
        const updatedLuckyDrawData = transformLuckyDrawData(formData, true);
        const { status } = props.currentDetail!;
        const isctive = status === 'ACTIVE';
        if (!isctive) { hitPostDraft() } else {
            Refs.pageToast.show({
                severity: 'success',
                message: "Lucky draw successfully edited."
            });
        }
        hitPostAPI(updatedLuckyDrawData.prize_rules, key)
    };

    const submitButtonClick = state.form.handleSubmit(async (formData) => {
        const updatedLuckyDrawData = transformLuckyDrawData(formData);
        hitPostAPI(updatedLuckyDrawData.prize_rules, 'SUBMIT');
    });

    const hitPostDraft = async () => {
        const payload: LuckyDrawModel = {
            id: props.id,
            is_draft: true,
        }
        await state.saveLuckyDrawGeneral.mutateAsync(payload)
    }

    const hitPostAPI = async (payload: LuckyDrawRuleModel[], key: string) => {
        try {
            await state.saveLuckyDrawRule.mutateAsync(payload)
            if (key === 'SUBMIT') {
                props.save && props.save()
            } else {
                props.saveDraft && props.saveDraft(key)
                state.rules.refetch()
            }
        } catch (error: any) {
            if (error?.response?.data?.response_schema?.response_code === 'PAKUWON-400') {
                const axiosResponse = error?.response ?? undefined
                if (axiosResponse) {
                    axiosResponse?.data?.response_output?.errors?.forEach((it: any) => {
                        state.form.setError(it.field, { type: 'custom', message: it.message.en })
                    })
                }
                window.scrollTo({
                    top: 350,
                    behavior: 'smooth',
                })
                Refs.pageToast.show({
                    severity: 'danger',
                    message: `${getErrorMessage(error, true)?.en}`
                });
            }
        }
    }

    const addNewStoreClicked = state.form.handleSubmit(async () => {
        state.addNewStore()
    })

    const getDisabled = (index: number) => {
        const prizeRulesLength = state.prizeRuleLength?.prize_rules_length ?? 0
        return prizeRulesLength > index
    }

    return (
        <>
            <HookFormProvider form={state.form} >
                <Stack className="p-6">
                    <p className="text-xs text-gray-400 dark:text-gray-500">{props.currentDetail?.name || '-'}</p>
                    {state.storeFields.map((_, storeIndex) => (
                        <Stack key={`store-${storeIndex}`}>
                            <div className='flex items-center justify-between'>
                                <Typography variant='h5'>Store {storeIndex + 1}</Typography>
                                {storeIndex > 0 &&
                                    <Button startIcon={DeleteIcon} variant='text' color='danger'
                                        disabled={getDisabled(storeIndex)}
                                        onClick={() => state.removeStore(storeIndex)}>
                                        Delete
                                    </Button>
                                }
                            </div>
                            <SelectAllCheckbox
                                label="Select All Store Category"
                                onChecked={(_) => {
                                    state.handleSyncCheckedAllStore(true, storeIndex)
                                }}
                                onUnChecked={() => {
                                    state.handleSyncCheckedAllStore(false, storeIndex)
                                }}
                                checked={
                                    (state.form.watch(`prize_rules.${storeIndex}.merchant_categories`)?.length === state.store_categories.data?.content?.length) &&
                                    (state.form.watch(`prize_rules.${storeIndex}.merchants`)?.length === state.store.data?.content?.length)
                                }
                                disabled={getDisabled(storeIndex)}
                                selectOption={(_) => ({
                                    label: _._!,
                                    value: _._!,
                                })}
                            />
                            <ControlLabel label="Store Category">
                                <InputSelect
                                    onChangeValue={(data) => state.handleSyncStoreCategory(data, storeIndex)}
                                    placeholder="Select store category"
                                    isMulti={true}
                                    options={state.store_categories.data?.content}
                                    rule={{ required: true }}
                                    form={state.form}
                                    name={`prize_rules.${storeIndex}.merchant_categories`}
                                    isDisabled={getDisabled(storeIndex)}
                                    selectOption={(_) => ({
                                        label: _.category_name!,
                                        value: _.id!,
                                    })}
                                />
                            </ControlLabel>
                            <ControlLabel label="Store">
                                <InputSelect
                                    onChangeValue={(data) => state.handleSyncStore(data, storeIndex)}
                                    placeholder="Select store"
                                    isMulti={true}
                                    options={state.store.data?.content}
                                    rule={{ required: true }}
                                    form={state.form}
                                    name={`prize_rules.${storeIndex}.merchants`}
                                    isDisabled={getDisabled(storeIndex)}
                                    selectOption={(_) => ({
                                        label: _.unique_merchant_name!,
                                        value: _.id!,
                                    })}
                                />
                            </ControlLabel>
                            <LuckyDrawTransaction id={props.id} storeIndex={storeIndex} prizeRuleLength={state.prizeRuleLength} />
                            <ControlLabel label=''>
                                <Button variant='outlined' onClick={addNewStoreClicked}>Add New Store</Button>
                            </ControlLabel>
                        </Stack>
                    ))}
                </Stack>
                <Stack
                    className={'px-8 py-6 border-t border-neutral-200'}
                    direction="row"
                    gap={4}
                >
                    {permission.setVisibleByAccess(
                        ['manage_campaign_lucky_draw.create'],
                        <Button variant='outlined' loading={state.saveLuckyDrawGeneral.isLoading} onClick={() => { isEdit ? saveAndExit() : alertSaveAsDraftRef.current?.show() }}>{'Save' + (!isEdit ? ' as Draft' : '')}</Button>,
                    )}

                    <Stack
                        direction="row"
                        justify='end'
                        gap={4}
                    >
                        <Button variant='outlined' loading={state.saveLuckyDrawRule.isLoading} onClick={() => { props.backward && props.backward() }}>Previous</Button>
                        {permission.setVisibleByAccess(
                            ['manage_campaign_lucky_draw.create'],
                            <Button loading={state.saveLuckyDrawRule.isLoading} onClick={() => submitButtonClick()}>Next</Button>,
                        )}

                    </Stack>
                </Stack>
            </HookFormProvider>

            <Alert
                cancelText="Go to List"
                submitText="Stay on page"
                onCancel={() => saveAsDraft('BACK')}
                onSubmit={() => saveAsDraft('STAY')}
                title="Save as Draft?"
                ref={alertSaveAsDraftRef}
                closeOnBackdropClicked={false}
            >
                <Typography>
                    Your progress will be saved as a draft. You can continue editing later.
                    <br />Would you like to stay on this page or return to the list?
                </Typography>
            </Alert>
        </>
    )
}