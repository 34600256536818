import { stepperTheme } from "./Stepper.theme";
import { CheckMarkIcon } from "../../Icons/ActionIcons/CheckmarkIcon";
import { StepperProps } from "./Stepper.types";

const Stepper = (props: StepperProps) => {
    return (
        <div className={`${stepperTheme.stepContainer} ${props.classNames}`}>
            {props.steps?.map((step, i) => {
                const isActive = props.currentStep === i + 1;
                const isComplete = i + 1 < props.currentStep;
                return (
                    <div
                        key={i}
                        className={`${stepperTheme.stepItem} ${stepperTheme.stepConnector} ${(isActive || isComplete) && "before:bg-primary-500"}`}
                    >
                        <div className={`${stepperTheme.step} ${isActive && "bg-primary text-white"} ${isComplete && "bg-primary-100"}`}>
                            {isComplete ? <CheckMarkIcon className="text-primary" /> : i + 1}
                        </div>
                        <p className={`${stepperTheme.stepWording} ${isComplete || isActive ? "visible" : "invisible"} ${isActive && "text-neutral-700"}`}>{step}</p>
                    </div>
                )
            })}
        </div>
    );
};

export default Stepper;