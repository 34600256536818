import { Button, ChipStatus, Content, ContentHeader, DialogRef, FilterMenu, InputSearch, Paging, QueryState, QueryStateContent, Stack, Table, TableBody, TableCell, TableHead, TableRow } from 'src/app/components'
import { usePermissions } from 'src/app/hooks/permissions-hook'
import { LuckyDrawModel, LuckyDrawParticipantModel } from 'src/app/modules/campaign/models/LuckyDraw.model'

import dayjs from 'dayjs'
import { FilterMenuContent } from 'src/app/components/Feedback/FilterMenu/FilterMenuContent.page'

export default function TabWinnerConsolation() {

    const permission = usePermissions()
    //   const state = useLuckyDrawHookDetailTabParticipant()
    //   const printCouponRef = useRef<DialogRef>(null);

    const renderItem = (item: LuckyDrawParticipantModel, index: number) => {
        return (
            <TableRow key={item.id}>
                <TableCell {...{ index }} />
                <TableCell value={item?.member_code} />
                <TableCell value={item?.fullname} />
                <TableCell value={item?.member_code} />
                <TableCell value={item?.member_code} />
                <TableCell
                    renderValue={(_) => dayjs(_).format('DD/MM/YYYY')}
                    className="w-[145px]"
                    value={new Date()}
                />
                <TableCell renderValue={(directories: LuckyDrawModel['directories']) => {
                    return (
                        <div className="w-[145px]">
                            {
                                directories?.map((directory, index) => {
                                    return (
                                        <p key={directory.id} className='whitespace-pre-wrap inline'>
                                            {directory.directory_name}
                                            {index < directories.length - 1 && <span>, </span>}
                                        </p>
                                    )
                                })
                            }
                        </div>
                    )
                }}
                    value={[{ directory_name: 'Pakuwon' }] as LuckyDrawModel['directories']}
                />
            </TableRow>
        )
    }

    return (
        <Content>
            <ContentHeader title="Consolation Prize">
                <FilterMenu>
                    <FilterMenuContent withStatus={false} />
                </FilterMenu>
            </ContentHeader>

            {/* <QueryState query={state.participantList}> */}
            <div className="px-8">
                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell index />
                            <TableCell>Customer ID</TableCell>
                            <TableCell>Customer Name</TableCell>
                            <TableCell>Phone Number</TableCell>
                            <TableCell>Email</TableCell>
                            <TableCell>Draw Date</TableCell>
                            <TableCell>Draw Location</TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {[].map(renderItem)}
                    </TableBody>
                </Table>
                {/* <Paging query={state.participantList} /> */}
            </div>
            <QueryStateContent title="Title is Empty" />
            {/* </QueryState> */}
        </Content>
    )
}
