import { BackButton, ChipStatus, Content, ContentHeader, LabelItem, QueryState, Thumbnail } from 'src/app/components';
import { PageTitle } from 'src/app/components/Layout/header/HeaderTitle';
import TabDrawTableContent from './TabDrawTableContent';


export default function TabDrawInfoContent() {
  const data: any = undefined //state.participantDetail.data;

  return (
    <>
      <div className='py-6 font-bold text-sm'>Customer Information</div>
      <LabelItem className='gap-1' label='Campaign ID' value={data?.member_code} />
      <LabelItem className='gap-1' label='Customer Name' value={data?.fullname} />
      <LabelItem className='gap-1' label='Gender' value={data?.gender} />
      <LabelItem className='gap-1' label='Phone Number' value={data?.phone_number} />
      <LabelItem className='gap-1' label='Email' value={data?.email} />
      <LabelItem className='gap-1' label='ID Type' value={data?.identity_type} />
      <LabelItem className='gap-1' label='ID Number' value={data?.identity_number} />
      <LabelItem className='gap-1' label='ID Photo'>
        <Thumbnail src={data?.identity_image} />
      </LabelItem>
      <LabelItem className='gap-1' label='Customer Status'>
        <ChipStatus state={data?.status} />
      </LabelItem>

      <div className='py-6 font-bold text-sm'>Coupon Information</div>
      <LabelItem className='gap-1' label='Coupon ID' value={data?.member_code} />
      <LabelItem className='gap-1' label='Receipt ID' value={data?.fullname} />
      <LabelItem className='gap-1' label='Print Location' value={data?.gender} />
      <LabelItem className='gap-1' label='Draw Location' value={data?.phone_number} />
      <LabelItem className='gap-1' label='Prize' value={data?.email} />
      <LabelItem className='gap-1' label='Print ID' value={data?.identity_type} />
      <LabelItem className='gap-1' label='Coupon Status'>
        <ChipStatus state={data?.status} />
      </LabelItem>
      <LabelItem className='gap-1' label='Remarks' value={data?.identity_number} />
      <div className='pt-6'>
        <TabDrawTableContent />
      </div>
    </>
  )
}