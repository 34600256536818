import {
  useLocation
} from "react-router-dom";
import {
  ChangeEvent,
  createContext,
  useContext,
  useEffect,
  useMemo,
  useState
} from "react";

import {
  FilterMenuContextProps,
  FilterMenuContextValue,
  FilterMenuProviderProps
} from "./FilterMenu.types";
import {
  filterCount
} from "./FilterMenu.utils";
import {
  safeArray
} from "src/app/utils/array-utils";
import {
  CheckboxProps
} from "../../Inputs/Checkbox/Checkbox.types";
import {
  RadioProps
} from "../../Inputs/Radio/Radio.types";

interface Draft { [key: string]: any };

const FilterMenuContext = createContext<FilterMenuContextValue<any>>({} as FilterMenuContextValue<any>);

export function FilterMenuProvider(props: FilterMenuProviderProps) {

  const [filterCount, setFilterCount] = useState<number>(0);
  const [isVisible, setVisible] = useState<boolean>(false);
  const [draft, setDraft] = useState<any>({});

  const updateDraft = (value: Draft) => {
    setDraft((_: any) => ({ ..._, ...value }));
  };

  const resetDraft = () => {
    const emptyDraft = Object.keys(draft).reduce((stack, item) => {
      return ({ ...stack, [item]: undefined });
    }, {});
    setDraft(emptyDraft);
    return emptyDraft;
  };

  const handleUpdateCheckbox = (event: ChangeEvent<HTMLInputElement>) => {
    const {
      checked,
      value,
      name
    } = event.target || {};
      if (value === 'all' && checked === true) {
        updateDraft({ [name]: ".all" });
        return
      }

    const values = safeArray(draft[name]?.split('.')).filter(_ => _ !== "all").filter((_) => !!_);
    const _values = checked ? [...values, value] : values.filter((_) => _ !== value);
    const _value = _values.reduce((_, __) => `${_}.${__}`, '');
    updateDraft({ [name]: _value });
  };

  const isCheckboxChecked = (name: any, value: any) => {
    return draft[name]?.includes(value);
  };

  const registerCheckbox = (props: CheckboxProps) => {
    return {
      ...props,
      onChange: handleUpdateCheckbox,
      checked: isCheckboxChecked(props.name, props.value)
    };
  };

  const registerRadio = (props: RadioProps): RadioProps => {
    const {
      value,
      name = 'default'
    } = props;
    return {
      ...props,
      onChange: (_) => updateDraft({ [name]: _.target.value }),
      checked: value === draft[name]
    }
  };

  const value = useMemo(
    () => ({
      registerCheckbox,
      registerRadio,
      setFilterCount,
      updateDraft,
      setDraft,
      resetDraft,
      setVisible,
      filterCount,
      isVisible,
      draft
    }),
    [
      JSON.stringify(draft),
      isVisible
    ]
  );

  return (
    <FilterMenuContext.Provider {...{ value }}>
      {props.children}
    </FilterMenuContext.Provider>
  );
}

export function useFilterMenuContext<D extends Draft>(props?: FilterMenuContextProps<D>): FilterMenuContextValue<D> {

  const location = useLocation();
  const context = useContext(FilterMenuContext);

  useEffect(() => {
    if (!!props?.defaultValues) {
      context.setFilterCount(filterCount(props?.defaultValues));
    }
  }, [location.search]);

  useEffect(() => {
    if (!context.isVisible && !!props?.defaultValues) {
      context.setDraft(props?.defaultValues);
    }
  }, [context.isVisible]);

  const searchParams = useMemo(() => {
    const urlParams = new URLSearchParams(location.search);
    const params: any = {}
    urlParams.forEach((value: string, key: string) => {
      params[key] = value;
    });
    return params;
  }, [location.search]);

  useEffect(() => {
    if (!searchParams.search) context.setDraft((prev:any)=>{
      return {...prev, search: undefined}
    })
  }, [location.search]);

  return context;
}
