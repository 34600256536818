import { Button, ChipStatus, Content, ContentHeader, DialogRef, InputSearch, Menu, MenuItem, Paging, QueryState, QueryStateContent, Stack, Table, TableBody, TableCell, TableHead, TableRow } from 'src/app/components'
import { usePermissions } from 'src/app/hooks/permissions-hook'
import { LuckyDrawModel, LuckyDrawParticipantModel } from 'src/app/modules/campaign/models/LuckyDraw.model'

import dayjs from 'dayjs'
import TabWinnerGrandPrize from './TabWinnerContent/TabWinnerGrandPrize'
import { XlsIcon } from 'src/app/components/Icons'
import TabWinnerConsolation from './TabWinnerContent/TabWinnerConsolationPrize'

export default function LuckyDrawDetailTabWinners() {

  const permission = usePermissions()
  //   const state = useLuckyDrawHookDetailTabParticipant()
  //   const printCouponRef = useRef<DialogRef>(null);

  return (
    <>
      <Stack
        direction="row"
        justify="between"
        items="center"
        className="pb-8"
      >
        <div className='text-2xl'>Grand Prize</div>
        <Menu
          closeOnClick={true}
          className="min-w-[160px]"
          menuButton={(
            <Button loading={false} variant="outlined">
              Export Data
            </Button>
          )}
        >
          <MenuItem startIcon={XlsIcon} onClick={() => (null)}>
            Export as XLS
          </MenuItem>
        </Menu>
      </Stack>
      <TabWinnerGrandPrize />
      <div className='py-8 text-2xl'>Consolation Prize</div>
      <TabWinnerConsolation />
    </>
  )
}
