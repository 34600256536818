import { Button, ChipStatus, Content, ContentHeader, DialogRef, InputSearch, Paging, QueryState, QueryStateContent, Stack, Table, TableBody, TableCell, TableHead, TableRow } from 'src/app/components'
import { usePermissions } from 'src/app/hooks/permissions-hook'
import { ParticipantCouponModel, ParticipantDetailModel } from 'src/app/modules/campaign/models/LuckyDraw.model'
import dayjs from 'dayjs'
import PrintCouponPopup from './PrintCouponPopup'
import { useRef } from 'react'
import { useTabDetailParticipantDetail } from './TabParticipantDetail.hook'

export default function CouponList(props: ParticipantDetailModel) {

  const permission = usePermissions()
  const state = useTabDetailParticipantDetail()
  const printCouponRef = useRef<DialogRef>(null);

  const getWinningStatus = (stat?: string) => {
    switch (stat) {
      case 'WON':
        return 'Winner';
      case 'LOST':
        return 'Non - Winner';
      case 'WAITING':
        return 'Awaiting Draw';
      default:
        return '-';
    }
  };

  const onPrintDone = () => {
    state.participantDetail.refetch()
    state.participantCouponList.refetch()
    state.participantReprintCouponList.refetch()
  }

  const renderItem = (item: ParticipantCouponModel, index: number) => {
    return (
      <TableRow key={item.id}>
        <TableCell {...{ index }} />
        <TableCell value={item?.coupon_code} />
        <TableCell value={item?.prize_name} />
        <TableCell value={item?.receipt_code} />
        <TableCell
          renderValue={(_) => dayjs(_).format('DD/MM/YYYY')}
          className="w-[145px]"
          value={item?.created_at}
        />
        <TableCell value={item?.is_printed ? 'Printed' : 'Not Yet'} />
        <TableCell
          renderValue={(_) => dayjs(_).format('DD/MM/YYYY hh:mm:ss')}
          className="w-[145px]"
          value={item?.printed_at}
        />
        <TableCell
          renderValue={(_) => dayjs(_).format('DD/MM/YYYY hh:mm:ss')}
          className="w-[145px]"
          value={item?.reprinted_at}
        />
        <TableCell value={item?.print_directory_name} />
        <TableCell value={item?.coupon_printed_code} />
        <TableCell>
          <ChipStatus state={item?.status} />
        </TableCell>
        <TableCell value={getWinningStatus(item?.winning_status)} />
      </TableRow>
    )
  }

  return (
    <>
      <Content>
        <ContentHeader title="Coupon List" />
        <Stack
          direction="row"
          justify="between"
          items="center"
          className="px-6 py-4"
        >
          <Stack
            direction="row"
            justify="start"
            items="center"
          >
            <InputSearch />
          </Stack>
          {permission.setVisibleByAccess(
            ['manage_campaign_lucky_draw_reprint.create'],
            <Button variant='outlined'
              disabled={((props.total_coupon_printed ?? 0) < 1) || props.status !== 'ACTIVE' || props.identity_status !== 'VERIFIED'}
              onClick={() => (printCouponRef.current?.show({ type: 'reprint', data: props }))}>
              Reprint coupon
            </Button>
          )}
          {permission.setVisibleByAccess(
            ['manage_campaign_lucky_draw_print.create'],
            <Button
              disabled={(props.is_unprinted_exist === false && props.is_unprinted_consolation_exist === false) || props.status !== 'ACTIVE' || props.identity_status !== 'VERIFIED'}
              onClick={() => (printCouponRef.current?.show({ type: 'print', coupondata: props }))}>
              Print all unprinted coupon
            </Button>
          )}
        </Stack>

        <QueryState query={state.participantCouponList}>
          <div className="px-8">
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell index />
                  <TableCell>Coupon ID</TableCell>
                  <TableCell>Prizes</TableCell>
                  <TableCell>Receipt ID</TableCell>
                  <TableCell>Obtained Date</TableCell>
                  <TableCell>Print Status</TableCell>
                  <TableCell>Printing Date</TableCell>
                  <TableCell>Reprint Date</TableCell>
                  <TableCell>Print Location</TableCell>
                  <TableCell>Print ID</TableCell>
                  <TableCell>Coupon Status</TableCell>
                  <TableCell>Draw Status</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {state.participantCouponList.data?.content?.map(renderItem)}
              </TableBody>
            </Table>
            <Paging query={state.participantCouponList} />
          </div>
          <QueryStateContent title="List Participant is Empty" />
        </QueryState>
      </Content>
      <PrintCouponPopup dialogRef={printCouponRef} onPrintDone={onPrintDone}></PrintCouponPopup>
    </>
  )
}
