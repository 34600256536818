import CampaignScreens from "./CampaignScreens";

import { Switch } from "react-router-dom";

import ProtectedRoute from "src/app/routers/ProtectedRoute";
import CampaignTabsPage from "./page/CampaignTabs/CampaignTabs.page";
import CampaignDetailPage from "./page/Campaign/CampaignDetail.page";
import CampaignAddPage from "./page/Campaign/CampaignAdd.page";
import CampaignEditPage from "./page/Campaign/CampaignEdit.page";
import LuckyDrawDetailPage from "./page/Campaign/LuckyDrawDetail/LuckyDrawDetail.page";
import TabParticipantDetail from './page/Campaign/LuckyDrawDetail/TabParticipantDetail/TabParticipantDetail';

const CampaignRoutes: React.FC = () => {
  return (
    <Switch>
      <ProtectedRoute
        path={CampaignScreens.CAMPAIGN.PATH}
        screen={CampaignScreens.CAMPAIGN}
        component={CampaignTabsPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.LIST_CAMPAIGN.PATH}
        screen={CampaignScreens.LIST_CAMPAIGN}
        component={CampaignTabsPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.ADD_CAMPAIGN.PATH}
        screen={CampaignScreens.ADD_CAMPAIGN}
        component={CampaignAddPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.DETAIL_CAMPAIGN.PATH}
        screen={CampaignScreens.DETAIL_CAMPAIGN}
        component={CampaignDetailPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.EDIT_CAMPAIGN.PATH}
        screen={CampaignScreens.EDIT_CAMPAIGN}
        component={CampaignEditPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.LIST_CAMPAIGN_HISTORY.PATH}
        screen={CampaignScreens.LIST_CAMPAIGN_HISTORY}
        component={CampaignTabsPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.DETAIL_CAMPAIGN_HISTORY.PATH}
        screen={CampaignScreens.DETAIL_CAMPAIGN_HISTORY}
        component={CampaignDetailPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.LIST_LUCKY_DRAW.PATH}
        screen={CampaignScreens.LIST_LUCKY_DRAW}
        component={CampaignTabsPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.DETAIL_LUCKY_DRAW.PATH}
        screen={CampaignScreens.DETAIL_LUCKY_DRAW}
        component={LuckyDrawDetailPage}
        exact={true}
      />
      <ProtectedRoute
        path={CampaignScreens.DETAIL_LUCKY_DRAW_DETAIL_PARTICIPANT.PATH}
        screen={CampaignScreens.DETAIL_LUCKY_DRAW_DETAIL_PARTICIPANT}
        component={TabParticipantDetail}
        exact={true}
      />
    </Switch>
  );
}

export default CampaignRoutes;
