import { PageTabsData } from "src/app/components";
import CampaignScreens from "../../CampaignScreens";

import { Campaign } from "../Campaign/Campaign.page";
import { CampaignHistory } from "../Campaign/CampaignHistory.page";
import { CampaignTabsPath } from "./CampaignTabs.types";
import { LuckyDraw } from '../Campaign/LuckyDraw.page';

export const campaignTabs: PageTabsData<CampaignTabsPath>[] = [
  {
    permission: 'manage_campaign_point.read',
    component: Campaign,
    label: 'Active Campaign',
    title: CampaignScreens.LIST_CAMPAIGN_ACTIVE.TITLE,
    path: 'active'
  },
  {
    permission: 'manage_campaign_lucky_draw.read',
    component: LuckyDraw,
    label: 'Lucky Draw',
    title: CampaignScreens.LIST_LUCKY_DRAW.TITLE,
    path: 'lucky-draw'
  },
  {
    permission: 'history_campaign.read',
    component: CampaignHistory,
    label: 'History Campaign',
    title: CampaignScreens.LIST_CAMPAIGN_HISTORY.TITLE,
    path: 'history'
  }
];
